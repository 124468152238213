import { FIELD_NAMES } from '@lmig-latam/adcl-common-lib/constants';
import { getCountryConfig } from '@lmig-latam/adcl-common-lib/countries';
import { getCountryCode } from '../../config/environments/environmentsHelpers';

const {
  SUBMISSION_ID,

  // Customer fields
  CUSTOMER,
  FIRST_NAME,
  LAST_NAME,
  CELL_PHONE_NUMBER,
  EMAIL,

  // Vehicle fields
  VEHICLE,
  PLATE,
} = FIELD_NAMES;

const FIELD_CONFIG = getCountryConfig(getCountryCode()).getFieldConfig();
const fieldConfig = {
  [SUBMISSION_ID]: FIELD_CONFIG[SUBMISSION_ID],

  [CUSTOMER]: [
    FIELD_CONFIG[CUSTOMER][FIRST_NAME],
    FIELD_CONFIG[CUSTOMER][LAST_NAME],
    FIELD_CONFIG[CUSTOMER][CELL_PHONE_NUMBER],
    FIELD_CONFIG[CUSTOMER][EMAIL],
  ],
  [PLATE]: FIELD_CONFIG[VEHICLE][PLATE],
};

Object.keys(fieldConfig).forEach(fieldSection => {
  if (fieldSection !== 'submissionId' && fieldSection !== 'plate') {
    fieldConfig[fieldSection] = fieldConfig[fieldSection].filter(
      field => typeof field !== 'undefined',
    );
  }
});

export const config = fieldConfig;

export default config;
