import React from 'react';
import { Button, Heading, IconButton } from '@lmig/lmds-react';
import { IconAutoDamage, IconClose } from '@lmig/lmds-react/icons';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { CircleImage } from '..';
import './styles.scss';

const DamageDescriptionModal = ({ isOpen, closeModal, title, description }) => {
  const textAreaElements = () => (
    <div disabled className="damage-description-modal-text">
      {description}
    </div>
  );

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="confirmation-modal"
      overlayClassName="confirmation-modal-overlay"
    >
      <div className="confirmation-modal-content">
        <div className="confirmation-modal-image-close-button">
          <IconButton onClick={closeModal}>
            <IconClose size="16" />
          </IconButton>
        </div>

        <CircleImage size="small">
          <IconAutoDamage size="64" />
        </CircleImage>

        <Heading type="h4-bold" className="auto-left-right-margin">
          {title}
        </Heading>

        {textAreaElements()}

        <div>
          <Button onClick={closeModal}>Cerrar</Button>
        </div>
      </div>
    </ReactModal>
  );
};

DamageDescriptionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default DamageDescriptionModal;
