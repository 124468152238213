import constants from '@lmig-latam/adcl-common-lib/constants';
import { getCountryConfig } from '@lmig-latam/adcl-common-lib/countries';
import { IconErrorAlert, IconTrash } from '@lmig/lmds-react/icons';
import { Content, Disclaimer, Heading } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ClaimActions, SettingsActions } from '../../actions';
import {
  AppHeader,
  ClaimAgentOverrideComment,
  ClaimAssignee,
  ClaimButtons,
  ClaimSummary,
  Comments,
  ConfirmationModal,
  CustomerDetails,
  CustomerRetakeModal,
  DamageDescriptionField,
  InlineLoader,
  SendNotificationsModal,
  SideBar,
  SupportingDocuments,
  VehiclePhotos,
} from '../../components';
import { getCountryCode } from '../../config/environments/environmentsHelpers';
import {
  claimPropType,
  customerMessagePropType,
  documentPropType,
  photoServicePropType,
} from '../../prop-types';
import { downloadFileFromUrl, photoSorter } from '../../utils';
import { notificationStatus, photoStatuses } from '../../utils/constants';
import localizedStrings from '../../utils/localizedStrings';
import {
  CLAIM_COMPLETE,
  DEFAULT,
  ERROR_GENERIC,
} from '../../utils/navigationConstants';
import { navigate } from '../../utils/NavigationUtils';
import './styles.scss';

const { CONFIRMATION, SUCCESS, FAILURE } = notificationStatus;

const {
  STATUS_TYPES: {
    STATUS_AGENT_APPROVED,
    STATUS_AGENT_REJECTED,
    STATUS_CUSTOMER_NEW,
    STATUS_CUSTOMER_IN_PROGRESS,
    STATUS_CUSTOMER_RETAKE,
  },
  DEFAULT_ASIGNEE,
} = constants;

const countryConfig = getCountryConfig(getCountryCode());

const { APPROVED, REJECTED } = photoStatuses;

const {
  CLAIM_TITLE,
  CLAIM_SUBTITLE,
  CLAIM_LOADING_LABEL,
  CLAIM_DELETE_MODAL_TITLE,
  CLAIM_DELETE_MODAL_SUBTITLE,
  CLAIM_DELETE_MODAL_CANCEL_BUTTON_LABEL,
  CLAIM_DELETE_MODAL_CONFIRM_BUTTON_LABEL,
  CLAIM_DELETE_MODAL_MESSAGE_INPUT_PLACEHOLDER,
  CLAIM_DELETE_MODAL_TEXTAREA_TITLE,
  CLAIM_OVERRIDE_MODAL_TITLE,
  CLAIM_OVERRIDE_MODAL_SUBTITLE,
  CLAIM_OVERRRIDE_MODAL_CANCEL_BUTTON_LABEL,
  CLAIM_OVERRIDE_MODAL_MESSAGE_INPUT_PLACEHOLDER,
  CLAIM_OVERRIDE_MODAL_TEXTAREA_APPROVE_TITLE,
  CLAIM_OVERRIDE_MODAL_TEXTAREA_REJECT_TITLE,
  CLAIM_APPROVE_BUTTON,
  CLAIM_REJECT_BUTTON,
} = localizedStrings;

const GET_DOCUMENTS_INDEX = 0;
const GET_CUSTOMER_MESSAGES_INDEX = 1;
const UPDATED_ASSIGNEE_INDEX = 2;

const Claim = ({
  claim: claimProp,
  photos: photosProp,
  documents: documentsProp,
  customerMessages: customerMessagesProp,
  username,
  isRead,
  isAdmin,
  name,
  displayImageAnalysis,
  ClaimActions: {
    updateClaimStatus,
    addCustomerMessage,
    deleteClaim,
    updateClaim,
    updatePhotos,
    getDocuments,
    uploadDocument,
    deleteDocument,
    getCustomerMessages,
    getClaim,
    getPhotos,
    getPhotosZip,
    resendCustomerNotifications,
    assignClaim,
  },
  settingsActions: { hideAppLoader, showAppLoader },
  match: {
    params: { claimId },
  },
}) => {
  const inlineLoadDefault =
    !claimProp || !photosProp || !documentsProp || !customerMessagesProp;

  const [claim, setClaim] = useState(claimProp);
  const [photos, setPhotos] = useState(
    photosProp ? photosProp.photos : undefined,
  );
  const [documents, setDocuments] = useState(documentsProp);
  const [customerMessages, setCustomerMessages] = useState(
    customerMessagesProp,
  );
  const [documentsToDelete, setDocumentsToDelete] = useState([]);
  const [newDocuments, setNewDocuments] = useState([]);
  const [newCustomerMessages, setNewCustomerMessages] = useState([]);
  const [inlineLoaderDisplayed, setInlineLoaderDisplayed] = useState(
    inlineLoadDefault,
  );
  const [claimDirty, setClaimDirty] = useState(false);
  const [photosDirty, setPhotosDirty] = useState(false);
  const [documentsDirty, setDocumentsDirty] = useState(false);
  const [newDocumentsDirty, setNewDocumentsDirty] = useState(false);
  const [newCustomerMessagesDirty, setNewCustomerMessagesDirty] = useState(
    false,
  );
  const [
    customerFieldValidationErrors,
    setCustomerFieldValidationErrors,
  ] = useState({});
  const [
    retakeInlineLoaderDisplayed,
    setRetakeInlineLoaderDisplayed,
  ] = useState(false);
  const [
    sendNotificationsLoaderDisplayed,
    setSendNotificationsLoaderDisplayed,
  ] = useState(false);
  const [sendNotificationsModalType, setSendNotificationsModalType] = useState(
    CONFIRMATION,
  );
  const [showMessageSuccess, setShowMessageSuccess] = useState(false);
  const [
    customerRetakeModalDisplayed,
    setCustomerRetakeModalDisplayed,
  ] = useState(false);
  const [
    deleteConfirmationModalDisplayed,
    setDeleteConfirmationModalDisplayed,
  ] = useState(false);
  const [isModalNotificationOpen, setIsNotificationModalOpen] = useState(false);
  const [photosZipDownloading, setPhotosZipDownloading] = useState(false);
  const [
    overrideConfirmationModalConfig,
    setOverrideConfirmationModalConfig,
  ] = useState({
    displayed: false,
    status: '',
  });

  const navigateToSearchScreen = () => {
    navigate(DEFAULT);
  };

  const navigateToErrorScreen = () => {
    navigate(ERROR_GENERIC);
  };

  const navigateToClaimAcceptedScreen = () => {
    navigate(CLAIM_COMPLETE, {
      status: STATUS_AGENT_APPROVED,
    });
  };

  const navigateToClaimRejectedScreen = () => {
    navigate(CLAIM_COMPLETE, {
      status: STATUS_AGENT_REJECTED,
    });
  };

  const updateCustomerFieldValidation = errors => {
    setCustomerFieldValidationErrors(errors);
  };

  const deleteExistingDocument = document => {
    const allDocuments = documentsToDelete.concat(document);
    const filteredDocuments = documents.filter(item => item !== document);
    setDocumentsToDelete(allDocuments);
    setDocuments(filteredDocuments);
    setDocumentsDirty(allDocuments.length > 0);
  };

  const deleteNewDocument = document => {
    const filteredDocuments = newDocuments.filter(item => item !== document);
    setNewDocuments(filteredDocuments);
    setNewDocumentsDirty(filteredDocuments.length > 0);
  };

  const filterExistingDocuments = documentsToAdd => {
    const allDocs = [...documents, ...newDocuments];
    return documentsToAdd.filter(doc =>
      allDocs.every(item => doc.name !== item.name),
    );
  };

  const addNewDocuments = documentsToAdd => {
    const filteredDocumentsToAdd = filterExistingDocuments(documentsToAdd);
    if (filteredDocumentsToAdd.length > 0) {
      setNewDocuments(newDocuments.concat(filteredDocumentsToAdd));
      setNewDocumentsDirty(true);
    }
  };

  const updateCustomer = customer => {
    setClaim({ ...claim, customer });
    setClaimDirty(true);
  };

  const updateExistingPhotos = existingPhotos => {
    setPhotos(existingPhotos);
    setPhotosDirty(true);
  };

  const updateComments = comments => {
    setClaim({ ...claim, comments });
    setClaimDirty(true);
  };

  const addNewCustomerMessage = newCustomerMessage => {
    const newCustomerMessageItem = {
      customerMessage: newCustomerMessage,
      agentId: username,
    };
    const allCustomerMessages = newCustomerMessages.concat(
      newCustomerMessageItem,
    );
    setNewCustomerMessages(allCustomerMessages);
    setNewCustomerMessagesDirty(allCustomerMessages.length > 0);
  };

  const showCustomerRetakeModal = () => {
    setCustomerRetakeModalDisplayed(true);
  };

  const hideCustomerRetakeModal = () => {
    setRetakeInlineLoaderDisplayed(false);
    setShowMessageSuccess(false);
    setCustomerRetakeModalDisplayed(false);
  };

  const showDeleteConfirmationModal = () => {
    setDeleteConfirmationModalDisplayed(true);
  };

  const hideDeleteConfirmationModal = () => {
    setDeleteConfirmationModalDisplayed(false);
  };

  const showOverrideConfirmationModal = status => {
    setOverrideConfirmationModalConfig({ displayed: true, status });
  };

  const hideOverrideConfirmationModal = () => {
    setOverrideConfirmationModalConfig({ displayed: false, status: '' });
  };

  const updateClaimState = claimStatus => {
    setClaim({
      ...claim,
      status: claimStatus,
    });
    setClaimDirty(true);
  };

  const isUnassigned = () =>
    claim.assignee === DEFAULT_ASIGNEE || !claim.assignee;

  const isAnythingDirty = () =>
    newCustomerMessagesDirty ||
    documentsDirty ||
    newDocumentsDirty ||
    photosDirty ||
    claimDirty;

  const saveClaim = async () => {
    try {
      const promises = [];

      // Update Photos
      if (photosDirty) {
        promises.push(updatePhotos(claim.id, photos));
      }

      // Upload New Documents
      if (newDocumentsDirty) {
        newDocuments.forEach(document => {
          promises.push(uploadDocument(claim.id, document));
        });
      }

      // Delete Documents
      if (documentsDirty) {
        documentsToDelete.forEach(document => {
          promises.push(deleteDocument(claim.id, document));
        });
      }

      await Promise.all(promises);

      // Now we have uploaded all the updated data, refresh certain data from the server
      // Including:
      // * Documents
      // * Customer Messages
      const updatedDataPromises = new Array(2);

      // Get the updated list of existing and newly uploaded documents
      if (newDocumentsDirty || documentsDirty) {
        updatedDataPromises[GET_DOCUMENTS_INDEX] = getDocuments(claim.id);
      }

      // Get the updated list of Customer Messages
      if (newCustomerMessagesDirty) {
        updatedDataPromises[GET_CUSTOMER_MESSAGES_INDEX] = getCustomerMessages(
          claim.id,
        );
      }

      // Auto assign claim on first update if its unassigned
      if (isUnassigned() && isAnythingDirty()) {
        const { id } = claim;
        updatedDataPromises[UPDATED_ASSIGNEE_INDEX] = assignClaim(id, name);
      }

      // Wait for the updated data
      const updatedData = await Promise.all(updatedDataPromises);

      // Update Claim
      if (claimDirty) {
        await updateClaim(claim);
      }

      // Mark all items as no longer dirty
      setClaimDirty(false);
      setPhotosDirty(false);
      setDocumentsDirty(false);
      setNewDocumentsDirty(false);
      setNewCustomerMessagesDirty(false);

      // If we have updated documents data, update the state
      if (updatedData[GET_DOCUMENTS_INDEX]) {
        setDocuments(updatedData[GET_DOCUMENTS_INDEX]);
        setDocumentsToDelete([]);
        setNewDocuments([]);
      }

      // If we have updated customer messages data, update the state
      if (updatedData[GET_CUSTOMER_MESSAGES_INDEX]) {
        setCustomerMessages(updatedData[GET_CUSTOMER_MESSAGES_INDEX]);
        setNewCustomerMessages([]);
      }

      // If we have assignee, update the state
      if (updatedData[UPDATED_ASSIGNEE_INDEX]) {
        const { assignee } = updatedData[UPDATED_ASSIGNEE_INDEX];
        setClaim({ ...claim, assignee });
      }
    } catch (error) {
      // Hide App Loader
      hideAppLoader();
    }
  };

  const isClaimReadyForApproveOrReject = () => {
    // Count the number of required photos present by comparing against the countryRequiredPhotoIds object
    const numberOfRequiredPhotos = photos.filter(photo =>
      countryConfig.countryRequiredPhotoIds.includes(photo.name),
    ).length;

    // Claim can be approved/rejected when ALL of the following conditions are met:

    // All required photos are present
    const allRequiredPhotosPresent =
      numberOfRequiredPhotos === countryConfig.countryRequiredPhotoIds.length;

    // All photos are in approved state
    const allPhotosApproved = photos.every(photo => photo.status === APPROVED);

    // The comments field is not empty
    const commentsArePresent = claim.comments.length > 0;

    // All fields pass the validation check
    const noValidationErrors = customerFieldValidationErrors.length === 0;

    // The claim has NOT been previously accepted or rejected
    const claimNotCompleted =
      [STATUS_AGENT_APPROVED, STATUS_AGENT_REJECTED].indexOf(claim.status) ===
      -1;

    return (
      allPhotosApproved &&
      allRequiredPhotosPresent &&
      commentsArePresent &&
      noValidationErrors &&
      claimNotCompleted
    );
  };

  const handleApprove = async reason => {
    try {
      updateClaimState(STATUS_AGENT_APPROVED);

      hideOverrideConfirmationModal();

      showAppLoader();

      await saveClaim();

      await updateClaimStatus(claim.id, STATUS_AGENT_APPROVED, name, reason);

      navigateToClaimAcceptedScreen();
    } catch (error) {
      navigateToErrorScreen();
    } finally {
      hideAppLoader();
    }
  };

  const handleReject = async comment => {
    try {
      updateClaimState(STATUS_AGENT_REJECTED);

      hideOverrideConfirmationModal();

      showAppLoader();

      await saveClaim();

      await updateClaimStatus(claim.id, STATUS_AGENT_REJECTED, name, comment);

      navigateToClaimRejectedScreen();
    } catch (error) {
      navigateToErrorScreen();
    } finally {
      hideAppLoader();
    }
  };

  const approveOrRejectClaim = (status, comment) => {
    if (status === STATUS_AGENT_APPROVED) {
      handleApprove(comment);
    }

    if (status === STATUS_AGENT_REJECTED) {
      handleReject(comment);
    }
  };

  const handleClaimCompletionFlow = status => {
    if (isClaimReadyForApproveOrReject()) {
      approveOrRejectClaim(status);
    } else {
      showOverrideConfirmationModal(status);
    }
  };

  const handleSendComments = async message => {
    try {
      const newCustomerMessage = {
        customerMessage: message,
        agentId: username,
      };

      const filterPhotos = photos.filter(item => item.status === REJECTED);
      const rejectedPhotos = filterPhotos.map(item => item.name);

      setRetakeInlineLoaderDisplayed(true);

      updateClaimState(STATUS_CUSTOMER_RETAKE);

      await addCustomerMessage(claim.id, newCustomerMessage);
      await saveClaim();
      await updateClaimStatus(
        claim.id,
        STATUS_CUSTOMER_RETAKE,
        null,
        null,
        rejectedPhotos,
      );
      setRetakeInlineLoaderDisplayed(false);
      setShowMessageSuccess(true);
    } catch (error) {
      navigateToErrorScreen();
    }
  };

  const handleReturn = () => {
    navigateToSearchScreen();
  };

  const handleSave = async () => {
    showAppLoader();
    await saveClaim();
    hideAppLoader();
  };

  const handleSaveAndExit = async () => {
    showAppLoader();
    await saveClaim();
    navigateToSearchScreen();
    hideAppLoader();
  };

  const handleDelete = async agentComments => {
    const deleteComment = {
      comments: agentComments,
    };

    const { id } = claim;

    showAppLoader();
    hideDeleteConfirmationModal();
    await deleteClaim(id, deleteComment);
    navigateToSearchScreen();
    hideAppLoader();
  };

  const anyRejectedPhotos = () =>
    photos.some(photo => photo.status === REJECTED);

  const openNotificationModal = () => {
    setIsNotificationModalOpen(true);
  };

  const closeNotificationModal = () => {
    setSendNotificationsModalType(CONFIRMATION);
    setIsNotificationModalOpen(false);
  };

  const sendNotifications = async () => {
    setSendNotificationsLoaderDisplayed(true);
    try {
      await saveClaim();
      await resendCustomerNotifications(claimId);
      setSendNotificationsModalType(SUCCESS);
    } catch (error) {
      setSendNotificationsModalType(FAILURE);
    }
    setSendNotificationsLoaderDisplayed(false);
  };

  const dataIsNotInValidStatus = status =>
    status !== STATUS_CUSTOMER_NEW &&
    status !== STATUS_CUSTOMER_IN_PROGRESS &&
    status !== STATUS_CUSTOMER_RETAKE;

  const handleAssignClaim = async () => {
    const { id } = claim;
    showAppLoader();
    const { assignee } = await assignClaim(id, name);

    setClaim({ ...claim, assignee });
    hideAppLoader();
  };

  const isAssignedToYou = () => claim.assignee === name;

  const isAssignedToSomeoneElse = () => !isAssignedToYou() && !isUnassigned();

  const isAssignButtonDisabled = () =>
    [STATUS_AGENT_APPROVED, STATUS_AGENT_REJECTED].includes(claim.status) ||
    isAssignedToYou();

  const isReadOnly = () =>
    [
      STATUS_CUSTOMER_NEW,
      STATUS_CUSTOMER_RETAKE,
      STATUS_AGENT_APPROVED,
      STATUS_AGENT_REJECTED,
    ].includes(claim.status) || isAssignedToSomeoneElse();

  const renderInlineLoader = () => (
    <div className="claim-loader auto-left-right-margin">
      <InlineLoader isLoading />
      <Heading type="h4-light" className="auto-left-right-margin">
        {CLAIM_LOADING_LABEL}
      </Heading>
    </div>
  );

  const downloadPhotosZip = async () => {
    setPhotosZipDownloading(true);

    const { photoZipUrl } = await getPhotosZip(claimId);

    downloadFileFromUrl(photoZipUrl, `${claimId}.zip`);

    setPhotosZipDownloading(false);
  };

  const agentOverrideCompletion = () => {
    if (!claim.agentOverrideComment) {
      return false;
    }

    if (
      claim.status !== STATUS_AGENT_APPROVED ||
      claim.status !== STATUS_AGENT_REJECTED
    ) {
      return false;
    }

    return true;
  };

  const renderClaim = () => {
    const readOnly = isReadOnly();

    // Customer Details need their own special case as they should be editable in situations when the rest should not
    const isCustomerDetailsReadOnly =
      isReadOnly() &&
      claim.status !== STATUS_CUSTOMER_NEW &&
      claim.status !== STATUS_CUSTOMER_IN_PROGRESS &&
      claim.status !== STATUS_CUSTOMER_RETAKE;

    return (
      <div>
        <Heading type="h3-light" className="auto-left-right-margin">
          {CLAIM_TITLE}
        </Heading>

        <Disclaimer>{CLAIM_SUBTITLE}</Disclaimer>

        {agentOverrideCompletion() && (
          <ClaimAgentOverrideComment
            agentName={claim.agentOverrideComment.agentId}
            overrideComment={claim.agentOverrideComment.overrideComment}
          />
        )}

        <ClaimAssignee
          assignee={claim.assignee}
          assignClaim={handleAssignClaim}
          assignDisabled={isAssignButtonDisabled()}
          showAssigneeButton={!isRead}
        />
        <ClaimSummary claim={claim} />

        <CustomerDetails
          customer={claim.customer}
          updateFieldValidation={updateCustomerFieldValidation}
          updateCustomer={updateCustomer}
          readOnly={isCustomerDetailsReadOnly}
          showNotificationModal={openNotificationModal}
          resendDisabled={
            dataIsNotInValidStatus(claim.status) || isCustomerDetailsReadOnly
          }
        />

        <DamageDescriptionField damageDescription={claim.damageDescription} />

        <VehiclePhotos
          photos={photos}
          downloadPhotosZip={downloadPhotosZip}
          photosZipDownloading={photosZipDownloading}
          updatePhotos={updateExistingPhotos}
          customerMessages={customerMessages}
          newCustomerMessages={newCustomerMessages}
          readOnly={readOnly || isUnassigned()}
          isRead={isRead}
          displayImageAnalysis={displayImageAnalysis}
        />
        {!isRead && (
          <SupportingDocuments
            documents={documents}
            newDocuments={newDocuments}
            deleteDocument={deleteExistingDocument}
            deleteNewDocument={deleteNewDocument}
            addNewDocuments={addNewDocuments}
            readOnly={readOnly}
          />
        )}
        {!isRead && (
          <Comments
            comments={claim.comments}
            updateComments={updateComments}
            readOnly={readOnly}
          />
        )}

        <CustomerRetakeModal
          showModalInlineLoader={retakeInlineLoaderDisplayed}
          showMessageSuccess={showMessageSuccess}
          isOpen={customerRetakeModalDisplayed}
          closeButtonHandler={hideCustomerRetakeModal}
          addNewCustomerMessage={addNewCustomerMessage}
          handleSendComments={handleSendComments}
        />
      </div>
    );
  };

  const doesClaimHaveAnyRejectedPhotos = () => {
    const numberOfRequiredPhotos = photos.filter(photo =>
      countryConfig.countryRequiredPhotoIds.includes(photo.name),
    ).length;

    const { status } = claim;

    const isClaimInValidStatus =
      status !== STATUS_CUSTOMER_RETAKE &&
      status !== STATUS_AGENT_APPROVED &&
      status !== STATUS_AGENT_REJECTED;

    const allRequiredPhotosPresent =
      numberOfRequiredPhotos === countryConfig.countryRequiredPhotoIds.length;

    const notAllPhotosApproved = anyRejectedPhotos();

    const allPhotosApprovedOrRejected = photos.every(
      photo => photo.status === APPROVED || photo.status === REJECTED,
    );

    return (
      allRequiredPhotosPresent &&
      notAllPhotosApproved &&
      allPhotosApprovedOrRejected &&
      isClaimInValidStatus
    );
  };

  const shouldEnableSaving = () =>
    customerFieldValidationErrors.length === 0 && !isReadOnly();

  const disableApproveRejectDelete = () =>
    claim.status === STATUS_AGENT_APPROVED ||
    claim.status === STATUS_AGENT_REJECTED;

  useEffect(() => {
    const preloadData = async () => {
      const promises = [];
      // Fetch claim if we don't already have it
      promises.push(claimProp || getClaim(claimId));

      // Fetch photos if we don't already have them
      promises.push(photosProp || getPhotos(claimId, { assignee: name }));

      // Fetch documents if we don't already have them
      promises.push(documentsProp || getDocuments(claimId));

      // Fetch customer messages if we don't already have them
      promises.push(customerMessagesProp || getCustomerMessages(claimId));

      const results = await Promise.all(promises);

      const [
        responseClaim,
        responsePhotos,
        responseDocuments,
        responseCustomerMessages,
      ] = results;

      // Hide the loader & update the state
      setClaim(responseClaim);
      setPhotos(photoSorter(responsePhotos.photos));
      setDocuments(responseDocuments);
      setCustomerMessages(responseCustomerMessages);
      setInlineLoaderDisplayed(false);
    };
    preloadData();
  }, [
    claimId,
    customerMessagesProp,
    documentsProp,
    getCustomerMessages,
    getDocuments,
    getClaim,
    getPhotos,
    claimProp,
    photosProp,
    name,
  ]);

  return (
    <div className="adcl-screen">
      <AppHeader />

      <SideBar>
        <div className="adcl-content">
          <Content>
            {inlineLoaderDisplayed ? renderInlineLoader() : renderClaim()}
          </Content>

          {!inlineLoaderDisplayed && (
            <ClaimButtons
              claimIsReadyForApproveOrReject={isClaimReadyForApproveOrReject()}
              showRetakeButton={doesClaimHaveAnyRejectedPhotos()}
              showCustomerRetakeModal={showCustomerRetakeModal}
              disableSave={!shouldEnableSaving()}
              disableApproveRejectDelete={disableApproveRejectDelete()}
              handleReturn={handleReturn}
              handleSave={handleSave}
              handleSaveAndExit={handleSaveAndExit}
              handleReject={() => {
                handleClaimCompletionFlow(STATUS_AGENT_REJECTED);
              }}
              handleApprove={() => {
                handleClaimCompletionFlow(STATUS_AGENT_APPROVED);
              }}
              handleDelete={showDeleteConfirmationModal}
              showDeleteButton={isAdmin}
              showOnlyReturnButton={isRead}
            />
          )}

          <ConfirmationModal
            isOpen={deleteConfirmationModalDisplayed}
            title={CLAIM_DELETE_MODAL_TITLE}
            subtitle={CLAIM_DELETE_MODAL_SUBTITLE}
            cancelButtonLabel={CLAIM_DELETE_MODAL_CANCEL_BUTTON_LABEL}
            confirmButtonLabel={CLAIM_DELETE_MODAL_CONFIRM_BUTTON_LABEL}
            cancelButtonHandler={hideDeleteConfirmationModal}
            confirmButtonHandler={handleDelete}
            textareaDisabled={false}
            textareaPlaceholderLabel={
              CLAIM_DELETE_MODAL_MESSAGE_INPUT_PLACEHOLDER
            }
            textareaTitleLabel={CLAIM_DELETE_MODAL_TEXTAREA_TITLE}
            Icon={IconTrash}
          />

          <ConfirmationModal
            isOpen={overrideConfirmationModalConfig.displayed}
            title={CLAIM_OVERRIDE_MODAL_TITLE}
            subtitle={CLAIM_OVERRIDE_MODAL_SUBTITLE}
            cancelButtonLabel={CLAIM_OVERRRIDE_MODAL_CANCEL_BUTTON_LABEL}
            confirmButtonLabel={
              overrideConfirmationModalConfig.status === STATUS_AGENT_APPROVED
                ? CLAIM_APPROVE_BUTTON
                : CLAIM_REJECT_BUTTON
            }
            cancelButtonHandler={hideOverrideConfirmationModal}
            confirmButtonHandler={reason => {
              approveOrRejectClaim(
                overrideConfirmationModalConfig.status,
                reason,
              );
            }}
            textareaDisabled={false}
            textareaPlaceholderLabel={
              CLAIM_OVERRIDE_MODAL_MESSAGE_INPUT_PLACEHOLDER
            }
            textareaTitleLabel={
              overrideConfirmationModalConfig.status === STATUS_AGENT_APPROVED
                ? CLAIM_OVERRIDE_MODAL_TEXTAREA_APPROVE_TITLE
                : CLAIM_OVERRIDE_MODAL_TEXTAREA_REJECT_TITLE
            }
            Icon={IconErrorAlert}
          />
        </div>
      </SideBar>

      <SendNotificationsModal
        modalType={sendNotificationsModalType}
        sendNotifications={sendNotifications}
        isNotificationsSending={sendNotificationsLoaderDisplayed}
        isModalNotificationOpen={isModalNotificationOpen}
        closeNotificationModal={closeNotificationModal}
      />
    </div>
  );
};

Claim.propTypes = {
  claim: claimPropType,
  photos: photoServicePropType,
  documents: PropTypes.arrayOf(documentPropType),
  customerMessages: PropTypes.arrayOf(customerMessagePropType),
  username: PropTypes.string.isRequired,
  isRead: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  displayImageAnalysis: PropTypes.bool.isRequired,
  ClaimActions: ClaimActions.PropertyTypes.isRequired,
  settingsActions: SettingsActions.PropertyTypes.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ claimId: PropTypes.string.isRequired }),
  }).isRequired,
};

Claim.defaultProps = {
  claim: undefined,
  photos: undefined,
  documents: undefined,
  customerMessages: undefined,
};

const mapStateToProps = ({
  user: { username, isRead, isAdmin, name },
  settings: {
    environment: { displayImageAnalysis },
  },
}) => ({
  username,
  isRead,
  isAdmin,
  name,
  displayImageAnalysis,
});

const mapDispatchToProps = dispatch => ({
  ClaimActions: bindActionCreators(ClaimActions, dispatch),
  settingsActions: bindActionCreators(SettingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Claim);
