import constants from '@lmig-latam/adcl-common-lib/constants';

const {
  STATUS_TYPES: {
    STATUS_AGENT_REVIEW,
    STATUS_AGENT_IN_PROGRESS,
    STATUS_AGENT_APPROVED,
    STATUS_CUSTOMER_RETAKE,
    STATUS_AGENT_REJECTED,
    STATUS_CUSTOMER_NEW,
    STATUS_CUSTOMER_IN_PROGRESS,
    STATUS_EXPIRED,
    STATUS_COMPLETED,
    STATUS_DELETED,
  },
} = constants;

export const getStatusColor = inspectionStatus => {
  const [blue, orange, green, yellowDark, red, gray] = [
    'blue',
    'orange',
    'green',
    'yellow-dark',
    'red',
    'gray',
  ];

  const colorStatus = {
    [STATUS_AGENT_REVIEW]: blue,
    [STATUS_AGENT_IN_PROGRESS]: orange,
    [STATUS_AGENT_APPROVED]: green,
    [STATUS_CUSTOMER_RETAKE]: yellowDark,
    [STATUS_AGENT_REJECTED]: red,
    [STATUS_CUSTOMER_NEW]: gray,
    [STATUS_CUSTOMER_IN_PROGRESS]: blue,
    [STATUS_EXPIRED]: red,
    [STATUS_COMPLETED]: green,
    [STATUS_DELETED]: red,
  };
  return colorStatus[inspectionStatus];
};
