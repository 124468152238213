import { combineReducers } from 'redux';
import settingsReducer from '../../reducers/settingsReducer';
import userReducer from '../../reducers/userReducer';
import { SearchReducer } from '../../redux/search-claims';

export default combineReducers({
  settings: settingsReducer,
  user: userReducer,
  searchFilter: SearchReducer,
});
