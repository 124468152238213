import { Button, Disclaimer, Heading, IconButton } from '@lmig/lmds-react';
import { IconChat, IconClose } from '@lmig/lmds-react/icons';
import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import { CircleImage } from '..';
import { customerMessagePropType } from '../../prop-types';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const {
  CLAIM_CUSTOMER_MESSAGES_TITLE,
  CLAIM_CUSTOMER_MESSAGES_BUTTON_LABEL,
} = localizedStrings;

const CustomerMessages = ({
  isOpen,
  closeButtonHandler,
  customerMessages,
  newCustomerMessages,
}) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={closeButtonHandler}
    className="customer-messages"
    overlayClassName="customer-messages-overlay"
  >
    <div className="customer-messages-content">
      <div className="customer-messages-image-close-button">
        <IconButton
          className="customer-messages-image-close-button-icon"
          onClick={closeButtonHandler}
        >
          <IconClose size="16" />
        </IconButton>
      </div>
      <Heading type="h4" className="auto-left-right-margin">
        {CLAIM_CUSTOMER_MESSAGES_TITLE}
      </Heading>
      <CircleImage size="small" className="customer-messages-icon">
        <IconChat size="48" />
      </CircleImage>
      <div className="customer-messages-list">
        {customerMessages.map(message => (
          <Disclaimer
            key={`customer-message-${message.customerMessage}`}
            className="customer-message-box"
          >
            {message.customerMessage}
          </Disclaimer>
        ))}
        {newCustomerMessages.map(message => (
          <Disclaimer
            key={`new-customer-message-${message.customerMessage}`}
            className="customer-message-box"
          >
            {message.customerMessage}
          </Disclaimer>
        ))}
      </div>
      <Button onClick={closeButtonHandler}>
        {CLAIM_CUSTOMER_MESSAGES_BUTTON_LABEL}
      </Button>
    </div>
  </ReactModal>
);

CustomerMessages.propTypes = {
  isOpen: PropTypes.bool.isRequired,

  closeButtonHandler: PropTypes.func.isRequired,

  customerMessages: PropTypes.arrayOf(customerMessagePropType).isRequired,
  newCustomerMessages: PropTypes.arrayOf(customerMessagePropType).isRequired,
};

export default CustomerMessages;
