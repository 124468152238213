export const prefillData = {
  submissionId: '123456',
  sourceApplicationId: 'UNQUORK',
  sourceApplicationEndpoint: 'www.unqork.lm.lmig.com/appraisalReturn',
  customer: {
    firstName: 'Sebastián',
    lastName: 'Sánchez',
    cellPhoneNumber: '55-123456789',
    email: 'test@test.com',
  },
  vehicle: {},
  plate: 'ABC123',
};
