import {
  FIELD_NAMES,
  STATUS_TYPES,
} from '@lmig-latam/adcl-common-lib/constants';
import { IconThumbsDown, IconThumbsUp } from '@lmig/lmds-react/icons';
import {
  BodyText,
  Button,
  Content,
  Disclaimer,
  GridCol,
  GridRow,
  Heading,
} from '@lmig/lmds-react';
import { Form, Scope } from 'informed';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ClaimActions, SettingsActions } from '../../actions';
import {
  AppHeader,
  CircleImage,
  ErrorsList,
  InformedAlphanumericInput,
  SideBar,
} from '../../components';
import { verifyFieldValidation } from '../../utils';
import localizedStrings from '../../utils/localizedStrings';
import { prefillData } from '../../utils/test-data/createClaimPrefill';
import { navigate } from '../../utils/NavigationUtils';
import { DEFAULT } from '../../utils/navigationConstants';
import { config } from './config';
import './styles.scss';

const {
  CREATE_CLAIM_CUSTOMER_TITLE,
  CREATE_CLAIM_CUSTOMER_SUBTITLE,
  CREATE_CLAIM_SUBMIT,
  CREATE_CLAIM_PREFILL,
  CREATE_CLAIM_CREATION_ERROR,
  CREATE_CLAIM_CREATION_SUCCESS,
  CREATE_CLAIM_CREATION_CLOSE,
  CREATE_CLAIM_CREATION_CONTINUE,
  CREATE_CLAIM_CREATION_COPY,
  CREATE_CLAIM_INVALID_PHONE_NUMBER,
  CLAIM_SUMMARY_HEADER_1,
  CLAIM_VEHICLE_SUBMISSIONID_EXIST,
} = localizedStrings;

const {
  STATUS_AGENT_REVIEW,
  STATUS_AGENT_IN_PROGRESS,
  STATUS_AGENT_APPROVED,
  STATUS_CUSTOMER_RETAKE,
  STATUS_AGENT_REJECTED,
  STATUS_CUSTOMER_NEW,
  STATUS_CUSTOMER_IN_PROGRESS,
  STATUS_EXPIRED,
} = STATUS_TYPES;

const getAllStatus = () =>
  [
    STATUS_AGENT_REVIEW,
    STATUS_AGENT_IN_PROGRESS,
    STATUS_AGENT_APPROVED,
    STATUS_CUSTOMER_RETAKE,
    STATUS_AGENT_REJECTED,
    STATUS_CUSTOMER_NEW,
    STATUS_CUSTOMER_IN_PROGRESS,
    STATUS_EXPIRED,
  ].join(',');

const getFormattedLabelVisual = ({ labelVisual, required, labelExample }) => {
  const labelParts = [labelVisual];

  if (labelExample) {
    labelParts.push(labelExample);
  }

  if (required) {
    labelParts.push('*');
  }

  return labelParts.join(' ');
};

const messageErrorSubmissionId = `${CLAIM_SUMMARY_HEADER_1}: ${CLAIM_VEHICLE_SUBMISSIONID_EXIST}`;

const getInformedInput = (field, scope) => {
  const { fieldName } = field;

  const validateFunction = verifyFieldValidation;

  const fieldProps = {
    field: fieldName,
    id: fieldName,
    labelVisual: getFormattedLabelVisual(field),
    validate: value => {
      // If a field is required OR if that field has been given a value then do a full validation check
      if (field.required || (value && value.length > 0)) {
        return validateFunction(value, field);
      }
      // Else do not validate that field and allow form submission
      return undefined;
    },
    initialValue: '',
    className: 'create-claim-input-padding',
    validateOnChange: true,
    validateOnBlur: true,
  };

  const inputComponent = <InformedAlphanumericInput {...fieldProps} />;

  return <GridCol key={`${scope}-${fieldName}`}>{inputComponent}</GridCol>;
};

const renderScopedInputs = (scope, fieldConfig, title, subtitle) => {
  const numberOfColumns = 2;

  // Copy the fieldConfig so we don't mutate it
  const formFields = [...fieldConfig];

  // If there's an extra field, pop it from the array so we can give it its own row later
  let extraField;
  if (formFields.length % numberOfColumns !== 0) {
    extraField = formFields.pop();
  }

  let fieldGridRows = [];

  // Pair the fields together into an array to be mapped later
  let counter = 0;
  while (counter < formFields.length) {
    fieldGridRows.push([formFields[counter++], formFields[counter++]]); // eslint-disable-line no-plusplus
  }

  // Convert the array of field configs into React components
  fieldGridRows = fieldGridRows.map(fieldGridRow => (
    <GridRow key={`${fieldGridRow[0].fieldName}-${fieldGridRow[1].fieldName}`}>
      {fieldGridRow.map(field => getInformedInput(field, scope))}
    </GridRow>
  ));

  // Give the extra field its own row
  if (extraField) {
    fieldGridRows.push(
      <GridRow key={`${extraField.fieldName}}`}>
        {getInformedInput(extraField, scope)}
      </GridRow>,
    );
  }

  return (
    <Scope scope={scope}>
      <div className="search-title-area">
        <Heading type="h3-light" className="auto-left-right-margin">
          {title}
        </Heading>
        <Disclaimer>{subtitle}</Disclaimer>
      </div>
      {fieldGridRows.map(fieldGridRow => fieldGridRow)}
    </Scope>
  );
};

const renderPrefillButton = (onClick, enableCreateClaimPrefill) =>
  enableCreateClaimPrefill && (
    <Button type="button" level="secondary" onClick={onClick}>
      {CREATE_CLAIM_PREFILL}
    </Button>
  );

const CreateClaim = ({
  ClaimActions: { createClaim, getClaims },
  settingsActions: { hideAppLoader, showAppLoader },
  enableCreateClaimPrefill,
  isReviewer,
}) => {
  const [formApi, setFormApi] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [claimLink, setClaimLink] = useState('');
  const [copyButtonSupported] = useState(
    !!(navigator && navigator.clipboard && navigator.clipboard.writeText),
  );
  const [clientErrorCode, setClientErrorCode] = useState(false);
  const [isExistSubmissionId, setIsExistSubmissionId] = useState(false);

  useEffect(() => {
    hideAppLoader();
  }, [hideAppLoader]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const isValidateClaim = async submissionId => {
    setIsExistSubmissionId(false);
    const dataParams = {};
    if (submissionId && submissionId.trim() !== '') {
      dataParams.status = getAllStatus();
      dataParams.filterString = submissionId;
    }

    const response = await getClaims(dataParams);
    if (response) {
      const { Items: claims } = response;
      claims.forEach(claim => {
        if (claim.submissionId === submissionId) {
          setIsExistSubmissionId(true);
        }
      });
    }
  };

  const getInformedValideInput = (field, scope) => {
    const { fieldName } = field;
    const validateFunction = verifyFieldValidation;
    const fieldProps = {
      field: fieldName,
      id: fieldName,
      labelVisual: getFormattedLabelVisual(field),
      validate: value => {
        // If a field is required OR if that field has been given a value then do a full validation check
        if (field.required || (value && value.length > 0)) {
          return validateFunction(value, field);
        }
        // Else do not validate that field and allow form submission
        return undefined;
      },
      onBlur: isValidateClaim,
      initialValue: '',
      className: 'create-claim-input-padding',
      validateOnChange: true,
      validateOnBlur: true,
    };

    const inputComponent = <InformedAlphanumericInput {...fieldProps} />;

    return <GridCol key={`${scope}-${fieldName}`}>{inputComponent}</GridCol>;
  };

  const createClaimHandler = async claim => {
    try {
      if (!isExistSubmissionId) {
        showAppLoader();
        const { link } = await createClaim(claim);
        setClaimLink(link);
        openModal();
        formApi.reset();
        hideAppLoader();
      }
    } catch (error) {
      setClientErrorCode(error.code);
      hideAppLoader();
      openModal();
    }
  };

  const setInformedApi = informedApi => {
    setFormApi(informedApi);
  };

  const resetModalState = () => {
    setIsModalOpen(false);
    setClaimLink('');
  };

  const closeModal = () => {
    if (isReviewer) {
      resetModalState();
      navigate(DEFAULT);
    } else {
      resetModalState();
    }
  };

  const copyClaimLink = () => {
    navigator.clipboard.writeText(claimLink);
  };

  const getErrorMessage = errorCode =>
    errorCode === 'SPI140' && CREATE_CLAIM_INVALID_PHONE_NUMBER;

  const renderCreateClaimResultsModal = () => {
    // If we have a claim link, then the creation process was a success
    const isSuccess = !!claimLink;

    const messageText = isSuccess
      ? CREATE_CLAIM_CREATION_SUCCESS
      : CREATE_CLAIM_CREATION_ERROR;

    const messageSubText = isSuccess
      ? claimLink
      : getErrorMessage(clientErrorCode);

    const messageIcon = isSuccess ? (
      <IconThumbsUp size="64" />
    ) : (
      <IconThumbsDown size="64" />
    );

    const displayCopyButton = isSuccess && copyButtonSupported;

    return (
      <ReactModal
        isOpen={isModalOpen}
        className="create-claim-modal"
        overlayClassName="create-claim-modal-overlay"
      >
        <div className="create-claim-modal-content">
          <CircleImage>{messageIcon}</CircleImage>
          <Heading type="h3-light">{messageText}</Heading>
          <BodyText>{messageSubText}</BodyText>

          <div className="create-claim-modal-buttons-area">
            {displayCopyButton && (
              <Button type="button" onClick={copyClaimLink}>
                {CREATE_CLAIM_CREATION_COPY}
              </Button>
            )}
            <Button type="button" level="secondary" onClick={closeModal}>
              {isReviewer
                ? CREATE_CLAIM_CREATION_CONTINUE
                : CREATE_CLAIM_CREATION_CLOSE}
            </Button>
          </div>
        </div>
      </ReactModal>
    );
  };

  return (
    <div className="adcl-screen">
      {renderCreateClaimResultsModal()}
      <AppHeader />
      <SideBar>
        <div className="adcl-content">
          <Content>
            <Form
              autoComplete="off"
              getApi={setInformedApi}
              onSubmit={createClaimHandler}
            >
              {({
                formState: {
                  errors: {
                    customer: customerErrors = {},
                    submissionId: submissionIdError = '',
                    plate: plateError = '',
                  },
                },
              }) => (
                <>
                  {getInformedValideInput(config.submissionId, null)}
                  <ErrorsList errors={[submissionIdError]} />
                  {isExistSubmissionId && (
                    <ErrorsList errors={[messageErrorSubmissionId]} />
                  )}
                  {getInformedInput(config.plate, null)}
                  <ErrorsList errors={[plateError]} />
                  {renderScopedInputs(
                    FIELD_NAMES.CUSTOMER,
                    config.customer,
                    CREATE_CLAIM_CUSTOMER_TITLE,
                    CREATE_CLAIM_CUSTOMER_SUBTITLE,
                  )}
                  <ErrorsList errors={Object.values(customerErrors)} />
                  <div className="create-claim-buttons-area">
                    {renderPrefillButton(() => {
                      formApi.setValues(prefillData);
                    }, enableCreateClaimPrefill)}
                    <Button type="submit">{CREATE_CLAIM_SUBMIT}</Button>
                  </div>
                </>
              )}
            </Form>
          </Content>
        </div>
      </SideBar>
    </div>
  );
};

CreateClaim.propTypes = {
  ClaimActions: ClaimActions.PropertyTypes.isRequired,
  settingsActions: SettingsActions.PropertyTypes.isRequired,
  enableCreateClaimPrefill: PropTypes.bool.isRequired,
  isReviewer: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  settings: {
    environment: { enableCreateClaimPrefill },
  },
  user: { isReviewer },
}) => ({ enableCreateClaimPrefill, isReviewer });

const mapDispatchToProps = dispatch => ({
  ClaimActions: bindActionCreators(ClaimActions, dispatch),
  settingsActions: bindActionCreators(SettingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateClaim);
