export const setMenuArrayFilter = (menu, idSelected) =>
  menu.map(item =>
    item.id === idSelected
      ? { ...item, active: true }
      : { ...item, active: false },
  );

export const getCountsClaims = (menuFilter, count) =>
  menuFilter.map(item => {
    const countNew = item.statuses.reduce(
      (total, status) => total + count[status],
      0,
    );
    return { ...item, count: countNew };
  });

export const resetMenuFilter = menuFilter =>
  menuFilter.map(item => ({ ...item, active: false }));

export const resetPageInChangeSubStatus = (currentLocation, newLocation) => {
  const isNewSubstatusDefined = newLocation.subStatusSelected !== undefined;
  const isDifferentSubstatus =
    currentLocation.subStatusSelected !== newLocation.subStatusSelected;

  if (isNewSubstatusDefined && isDifferentSubstatus) {
    return { ...currentLocation, ...newLocation, currentPageIndex: 0 };
  }
  return { ...currentLocation, ...newLocation };
};

export const resetLocationInSearch = state => {
  const resetLocation =
    state.currentLocation.subStatusSelected || state.filterSelected;

  return resetLocation
    ? {
        ...state.currentLocation,
        mustHavePhotos: false,
        subStatusSelected: undefined,
        currentPageIndex: 0,
      }
    : {
        ...state.currentLocation,
        mustHavePhotos: false,
        subStatusSelected: undefined,
      };
};
