import API from '@aws-amplify/api';
import { getEnvironmentConfig } from '../../config/environments';
import { ERROR_GENERIC } from '../../utils/navigationConstants';
import localizedStrings from '../../utils/localizedStrings';
import { navigate } from '../../utils/NavigationUtils';
import { buildBody } from './build-request';
import { getRequestHeaders } from './headers';

const {
  ERROR_MESSAGE_ACCESS_DENIED_TITLE,
  ERROR_MESSAGE_ACCESS_DENIED_SUBTITLE,
} = localizedStrings;

export const sendServiceCall = (
  path,
  body = {},
  disableErrorHandling = false,
  method,
) => {
  const environment = getEnvironmentConfig();
  const apiGatewayName = environment.awsConfig.API.endpoints[0].name;

  const request = {
    headers: getRequestHeaders(),
  };

  request.body = buildBody(body, method);

  return API[method](apiGatewayName, `/${path}`, request).then(
    async response => Promise.resolve(response),
    error => {
      let navigationState;

      try {
        if (error.response.data.code === 'INSS130') {
          navigationState = {
            title: ERROR_MESSAGE_ACCESS_DENIED_TITLE,
            subtitle: ERROR_MESSAGE_ACCESS_DENIED_SUBTITLE,
          };
        }
      } catch (e) {
        navigationState = {};
      }

      return disableErrorHandling
        ? Promise.reject(error.response.data)
        : navigate(ERROR_GENERIC, navigationState);
    },
  );
};
