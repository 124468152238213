import { Caption, IconButton } from '@lmig/lmds-react';
import {
  IconAuto,
  IconMyAccount,
  IconDownload,
  IconClose,
} from '@lmig/lmds-react/icons';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const {
  CLAIM_VEHICLE_PHOTOS_MODAL_DOWNLOAD_IMAGE,
  CLAIM_VEHICLE_PHOTOS_IMAGE_VEHICLE_TOOLTIP,
  CLAIM_VEHICLE_PHOTOS_IMAGE_PERSON_TOOLTIP,
} = localizedStrings;

const PhotoModal = ({
  isOpen,
  closeButtonHandler,
  imageUrl,
  imageAlt,
  displayImageContainsVehicleIcon,
  displayImageContainsPersonIcon,
}) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={closeButtonHandler}
    className="photo-modal"
    overlayClassName="photo-modal-overlay"
  >
    <div className="photo-modal-content">
      <div className="photo-modal-close-link">
        <IconButton
          className="photo-modal-image-close-button"
          onClick={closeButtonHandler}
        >
          <IconClose
            size="16"
            className="photo-modal-image-close-button-icon"
          />
        </IconButton>
      </div>
      <img src={imageUrl} alt={imageAlt} />
      <div className="photo-modal-image-footer">
        <div className="photo-modal-icons">
          {displayImageContainsVehicleIcon && (
            <IconAuto
              color="success"
              size="16"
              data-tip={CLAIM_VEHICLE_PHOTOS_IMAGE_VEHICLE_TOOLTIP}
              title=""
              className="photo-modal-icon"
            />
          )}
          {displayImageContainsPersonIcon && (
            <IconMyAccount
              color="error"
              size="16"
              data-tip={CLAIM_VEHICLE_PHOTOS_IMAGE_PERSON_TOOLTIP}
              title=""
              className="photo-modal-icon"
            />
          )}
          <ReactTooltip type="light" effect="solid" border />
        </div>
        <a href={imageUrl} download>
          <Caption>{CLAIM_VEHICLE_PHOTOS_MODAL_DOWNLOAD_IMAGE}</Caption>
          <IconDownload
            className="photo-modal-image-download-button-icon"
            size="16"
          />
        </a>
      </div>
    </div>
  </ReactModal>
);

PhotoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeButtonHandler: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  displayImageContainsVehicleIcon: PropTypes.bool.isRequired,
  displayImageContainsPersonIcon: PropTypes.bool.isRequired,
};

export default PhotoModal;
