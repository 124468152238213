import {
  ONCHANGE_CURRENT_LOCATION_PAGE,
  ONCHANGE_SEARCH_TEXT_CLAIMS,
  RESET_FILTER_STEPS,
  SELECT_STEP_FILTER_SEARCH,
  SET_COUNTS_CLAIMS,
} from './constants';

import menuFilterArray from '../../constants/menu';
import {
  resetLocationInSearch,
  getCountsClaims,
  resetMenuFilter,
  resetPageInChangeSubStatus,
  setMenuArrayFilter,
} from './helpers';

export const initialState = {
  filterText: '',
  menuFilter: menuFilterArray,
  filterSelected: undefined,
  countClaims: [],
  currentLocation: {
    currentPageIndex: 0,
    mustHavePhotos: false,
    subStatusSelected: undefined,
  },
};

export default (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case SELECT_STEP_FILTER_SEARCH:
      return {
        ...state,
        filterText: '',
        filterSelected: payload.filter,
        menuFilter: setMenuArrayFilter(state.menuFilter, payload.filter.id),
        currentLocation: {
          ...state.currentLocation,
          currentPageIndex: 0,
          mustHavePhotos: false,
          subStatusSelected: undefined,
        },
      };

    case SET_COUNTS_CLAIMS:
      return {
        ...state,
        menuFilter: getCountsClaims(state.menuFilter, payload.count),
        countClaims: payload.count,
      };

    case ONCHANGE_SEARCH_TEXT_CLAIMS:
      return {
        ...state,
        filterText: payload.text,
        currentLocation: resetLocationInSearch(state),
      };

    case ONCHANGE_CURRENT_LOCATION_PAGE:
      return {
        ...state,
        currentLocation: resetPageInChangeSubStatus(
          state.currentLocation,
          payload.location,
        ),
      };

    case RESET_FILTER_STEPS:
      return {
        ...state,
        menuFilter: resetMenuFilter(state.menuFilter),
        filterSelected: undefined,
        subStatusSelected: undefined,
      };
    default:
      return state;
  }
};
