import React from 'react';
import constants from '@lmig-latam/adcl-common-lib/constants';
import { TableCell, TableRow } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import { claimPropType } from '../../prop-types';
import Review from '../../styles/images/steps/peding-review.svg';
import localizedStrings from '../../utils/localizedStrings';
import { getStatusColor } from '../../utils/status-color';
import photoAlert from './camara.svg';
import DamageDescriptionIcon from '../../styles/images/damage-description.svg';
import { Image, Chip } from '..';
import {
  formatISOString,
  getAssigneeDisplayName,
  getClaimStatusDisplayName,
} from '../../utils';
import './styles.scss';

const {
  STATUS_TYPES: { STATUS_CUSTOMER_IN_PROGRESS },
} = constants;

const {
  CLAIMS_TABLE_ACTION_DETAIL_BUTTON,
  CLAIMS_TABLE_ACTION_DAMAGE_DESCRIPTION_BUTTON,
  CLAIMS_TABLE_HEADER_NUMBER,
  CLAIMS_TABLE_HEADER_NAME,
  CLAIMS_TABLE_HEADER_ASSIGNEE,
  CLAIMS_TABLE_HEADER_ACTION,
  CLAIMS_TABLE_ROW_PHOTO_ALERT_TOOLTIP,
  CLAIMS_TABLE_ROW_PHOTO_ALERT_ICON_ALT_TEXT,
  CLAIMS_TABLE_HEADER_PLATE,
} = localizedStrings;

const NOT_APPLICABLE = 'N/A';

const renderPhotoIcon = isCustomerInProgress =>
  isCustomerInProgress && (
    <div
      className="claim-row-color-band"
      data-tip={CLAIMS_TABLE_ROW_PHOTO_ALERT_TOOLTIP}
    >
      <img src={photoAlert} alt={CLAIMS_TABLE_ROW_PHOTO_ALERT_ICON_ALT_TEXT} />
      <ReactTooltip type="light" effect="solid" border />
    </div>
  );

const ClaimTableRow = ({
  claim: {
    id,
    assignee,
    customer: { firstName, lastName },
    status,
    photos,
    submissionId,
    createdBy = NOT_APPLICABLE,
    creationDate,
    emailTokenCreationDate,
    damageDescription,
    plate,
  },
  isGrey,
  onClick,
}) => {
  const isCustomerInProgress = status === STATUS_CUSTOMER_IN_PROGRESS;

  const customerDisplayName = [firstName, lastName].join(' ');

  const createdOn = emailTokenCreationDate
    ? formatISOString(emailTokenCreationDate)
    : formatISOString(creationDate);

  const onSubmissionID = () =>
    submissionId === NOT_APPLICABLE || !submissionId
      ? ''
      : submissionId.toUpperCase();

  const plateDisplay = plate || '';

  return (
    <TableRow
      key={id}
      className={classNames({
        'adcl-table-row-grey': isGrey,
      })}
    >
      <TableCell
        type="data"
        dataHeader={CLAIMS_TABLE_HEADER_NUMBER}
        className="claim-row-first-cell"
      >
        <div className="claim-row-first-cell-content">
          {photos && photos.length > 0 && renderPhotoIcon(isCustomerInProgress)}
          <div className="claim-row-content">{onSubmissionID()}</div>
        </div>
      </TableCell>

      <TableCell type="data" dataHeader={CLAIMS_TABLE_HEADER_NUMBER}>
        {createdBy}
      </TableCell>

      <TableCell type="data" dataHeader={CLAIMS_TABLE_HEADER_NUMBER}>
        {createdOn}
      </TableCell>

      <TableCell type="data" dataHeader={CLAIMS_TABLE_HEADER_PLATE}>
        {plateDisplay}
      </TableCell>

      <TableCell type="data" dataHeader={CLAIMS_TABLE_HEADER_NAME}>
        {customerDisplayName}
      </TableCell>

      <TableCell type="data" dataHeader={CLAIMS_TABLE_HEADER_ASSIGNEE}>
        {getAssigneeDisplayName(assignee)}
      </TableCell>

      <TableCell type="data" dataHeader={CLAIMS_TABLE_HEADER_ASSIGNEE}>
        <Chip
          color={getStatusColor(status)}
          text={getClaimStatusDisplayName(status)}
        />
      </TableCell>

      <TableCell type="data" dataHeader={CLAIMS_TABLE_HEADER_ACTION}>
        <div className="section-action-button">
          <Image
            onClick={() => onClick(CLAIMS_TABLE_ACTION_DETAIL_BUTTON, id)}
            className="action-button-detail"
            data-tip={CLAIMS_TABLE_ACTION_DETAIL_BUTTON}
            alt={CLAIMS_TABLE_ACTION_DETAIL_BUTTON}
            src={Review}
          />

          {damageDescription && (
            <Image
              onClick={
                () =>
                  onClick(
                    CLAIMS_TABLE_ACTION_DAMAGE_DESCRIPTION_BUTTON,
                    damageDescription,
                  )
                // eslint-disable-next-line react/jsx-curly-newline
              }
              className="action-button-damage"
              data-tip={CLAIMS_TABLE_ACTION_DAMAGE_DESCRIPTION_BUTTON}
              alt={CLAIMS_TABLE_ACTION_DAMAGE_DESCRIPTION_BUTTON}
              src={DamageDescriptionIcon}
            />
          )}

          <ReactTooltip type="light" effect="solid" border />
        </div>
      </TableCell>
    </TableRow>
  );
};

ClaimTableRow.propTypes = {
  claim: claimPropType.isRequired,
  isGrey: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

ClaimTableRow.defaultProps = {
  isGrey: false,
};

export default ClaimTableRow;
