import { Radio, RadioGroup, TableCell, TableRow } from '@lmig/lmds-react';
import { IconAuto, IconMyAccount } from '@lmig/lmds-react/icons';
import { photoUtils } from '@lmig-latam/adlib-ui';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { PhotoModal } from '..';
import { getPhotoDisplayStringFromId } from '../../config/photoDisplayStringMapping';
import { photoPropType } from '../../prop-types';
import { getAgeSince } from '../../utils';
import { photoStatuses } from '../../utils/constants';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const { doesImageContainPerson, doesImageContainVehicle } = photoUtils;

const { APPROVED, REJECTED } = photoStatuses;

const {
  CLAIM_VEHICLE_PHOTOS_RADIO_BUTTON_ACCEPT,
  CLAIM_VEHICLE_PHOTOS_RADIO_BUTTON_REJECT,
  CLAIM_VEHICLE_PHOTOS_HEADER_2,
  CLAIM_VEHICLE_PHOTOS_HEADER_3,
  CLAIM_VEHICLE_PHOTOS_HEADER_4,
  CLAIM_VEHICLE_PHOTOS_IMAGE_VEHICLE_TOOLTIP,
  CLAIM_VEHICLE_PHOTOS_IMAGE_PERSON_TOOLTIP,
} = localizedStrings;

const VehiclePhotoRow = ({
  photo,
  updatePhoto,
  isGrey,
  readOnly,
  isRead,
  displayImageAnalysis,
}) => {
  const [photoModalDisplayed, setPhotoModalDisplayed] = useState(false);

  const showPhotoModal = () => {
    setPhotoModalDisplayed(true);
  };

  const hidePhotoModal = () => {
    setPhotoModalDisplayed(false);
  };

  const onChangeRadioButton = event => {
    updatePhoto({ ...photo, status: event.target.value });
  };

  const { url, name, ageInSeconds, status, tags } = photo;

  const displayImageContainsVehicleIcon =
    displayImageAnalysis && doesImageContainVehicle(tags);

  const displayImageContainsPersonIcon =
    displayImageAnalysis && doesImageContainPerson(tags);

  const photoDisplayName = getPhotoDisplayStringFromId(name);

  return (
    <TableRow
      className={classNames({
        'adcl-table-row-grey': isGrey,
      })}
    >
      <TableCell type="data" className="vehicle-photo-row-thumbnail-cell">
        <div
          className="vehicle-photo-row-thumbnail"
          onClick={showPhotoModal}
          role="button"
        >
          <img
            src={url}
            alt={name}
            className="vehicle-photo-row-thumbnail-image"
          />
          <div className="vehicle-photo-row-image-popup">
            <img src={url} alt={name} />
          </div>
        </div>
        <PhotoModal
          isOpen={photoModalDisplayed}
          closeButtonHandler={hidePhotoModal}
          imageUrl={url}
          imageAlt={name}
          displayImageContainsVehicleIcon={displayImageContainsVehicleIcon}
          displayImageContainsPersonIcon={displayImageContainsPersonIcon}
        />
      </TableCell>
      <TableCell>
        <div className="vehicle-photo-row-icons">
          <div>
            {displayImageContainsVehicleIcon && (
              <IconAuto
                color="success"
                size="16"
                data-tip={CLAIM_VEHICLE_PHOTOS_IMAGE_VEHICLE_TOOLTIP}
                title=""
                className="vehicle-photo-row-icon"
              />
            )}
            {displayImageContainsPersonIcon && (
              <IconMyAccount
                color="error"
                size="16"
                data-tip={CLAIM_VEHICLE_PHOTOS_IMAGE_PERSON_TOOLTIP}
                title=""
                className="vehicle-photo-row-icon"
              />
            )}
          </div>
          <ReactTooltip type="light" effect="solid" border />
        </div>
      </TableCell>
      <TableCell type="data" dataHeader={CLAIM_VEHICLE_PHOTOS_HEADER_2}>
        {photoDisplayName}
      </TableCell>
      <TableCell type="data" dataHeader={CLAIM_VEHICLE_PHOTOS_HEADER_3}>
        {getAgeSince(ageInSeconds)}
      </TableCell>
      {!isRead && (
        <TableCell type="data" dataHeader={CLAIM_VEHICLE_PHOTOS_HEADER_4}>
          <RadioGroup
            inline
            name={`vehicle-photo-row-${name}-radio-group`}
            onChange={onChangeRadioButton}
            value={status}
            className="vehicle-photo-row-radio-group"
            disabled={readOnly}
          >
            <Radio
              id={`vehicle-photo-row-${name}-approve`}
              key={`vehicle-photo-row-${name}-approve`}
              labelVisual={CLAIM_VEHICLE_PHOTOS_RADIO_BUTTON_ACCEPT}
              value={APPROVED}
            />
            <Radio
              id={`vehicle-photo-row-${name}-reject`}
              key={`vehicle-photo-row-${name}-reject`}
              labelVisual={CLAIM_VEHICLE_PHOTOS_RADIO_BUTTON_REJECT}
              value={REJECTED}
            />
          </RadioGroup>
        </TableCell>
      )}
    </TableRow>
  );
};

VehiclePhotoRow.propTypes = {
  isGrey: PropTypes.bool,
  photo: photoPropType.isRequired,
  updatePhoto: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  isRead: PropTypes.bool,
  displayImageAnalysis: PropTypes.bool.isRequired,
};

VehiclePhotoRow.defaultProps = {
  isGrey: false,
  readOnly: false,
  isRead: false,
};

export default VehiclePhotoRow;
