import { GridRow, Button, Caption } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React from 'react';
import { getAssigneeDisplayName } from '../../utils';
import { Panel } from '..';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const {
  CLAIM_ASSIGNEE_ASSIGN_TO_ME_BUTTON,
  CLAIM_ASSIGNEE_TITLE,
} = localizedStrings;

const ClaimAssignee = ({
  assignee,
  assignClaim,
  assignDisabled,
  showAssigneeButton,
}) => (
  <Panel className="assignee-section">
    <GridRow vAlign="middle">
      <Caption className="assignee-text assignee-text-title">
        {CLAIM_ASSIGNEE_TITLE}
      </Caption>
      <Caption className="assignee-text">
        {getAssigneeDisplayName(assignee)}
      </Caption>
      {showAssigneeButton && (
        <Button
          type="button"
          level="primary"
          onClick={assignClaim}
          className="assignee-button"
          disabled={assignDisabled}
        >
          <span>{CLAIM_ASSIGNEE_ASSIGN_TO_ME_BUTTON}</span>
        </Button>
      )}
    </GridRow>
  </Panel>
);

ClaimAssignee.propTypes = {
  assignDisabled: PropTypes.bool.isRequired,
  assignClaim: PropTypes.func.isRequired,
  assignee: PropTypes.string,
  showAssigneeButton: PropTypes.bool.isRequired,
};

ClaimAssignee.defaultProps = {
  assignee: null,
};

export default ClaimAssignee;
