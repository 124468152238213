import React, { forwardRef } from 'react';
import { AlphanumericInput } from '@lmig/lmds-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './styles.scss';

const CellphoneField = forwardRef((props, ref) => {
  const { className, error } = props;

  return (
    <AlphanumericInput
      {...props}
      innerRef={ref}
      className={classNames([
        className,
        {
          'validation-input-field-error': error,
        },
      ])}
    />
  );
});

CellphoneField.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
};

CellphoneField.defaultProps = {
  className: null,
  error: null,
};

export default CellphoneField;
