import { Disclaimer, Heading } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Panel } from '..';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const {
  CLAIM_COMMENTS_TITLE,
  CLAIM_COMMENTS_SUBTITLE,
  CLAIM_COMMENTS_INPUT_PLACEHOLDER,
} = localizedStrings;

const Comments = ({ comments, updateComments, readOnly }) => {
  const updateCommentsHandler = event => {
    updateComments(event.target.value);
  };

  return (
    <Panel className="comments">
      <Heading type="h3-light" className="auto-left-right-margin">
        {CLAIM_COMMENTS_TITLE}
      </Heading>
      <Disclaimer>{CLAIM_COMMENTS_SUBTITLE}</Disclaimer>
      <textarea
        className="comments-text-area"
        onChange={updateCommentsHandler}
        placeholder={CLAIM_COMMENTS_INPUT_PLACEHOLDER}
        value={comments}
        maxLength="1000"
        disabled={readOnly}
      />
    </Panel>
  );
};

Comments.propTypes = {
  comments: PropTypes.string.isRequired,
  updateComments: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

Comments.defaultProps = {
  readOnly: false,
};

export default Comments;
