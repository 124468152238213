import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableCell, TableRow } from '@lmig/lmds-react';
import { useDispatch, useSelector } from 'react-redux';
import { ClaimTableRow, Panel } from '..';
import { claimPropType } from '../../prop-types';
import arrowUp from './arrow-up.svg';
import arrowDown from './arrow-down.svg';
import { SearchActions } from '../../redux/search-claims';
import localizedStrings from '../../utils/localizedStrings';
import { CLAIM } from '../../utils/navigationConstants';
import { navigate } from '../../utils/NavigationUtils';
import InformedSelect from '../InformedSelect';
import './styles.scss';

const {
  CLAIMS_TABLE_HEADER_NUMBER,
  CLAIMS_TABLE_HEADER_NAME,
  CLAIMS_TABLE_HEADER_ACTION,
  CLAIMS_TABLE_HEADER_ASSIGNEE,
  CLAIMS_TABLE_HEADER_CREATED_BY,
  CLAIMS_TABLE_HEADER_CREATED_ON,
  CLAIMS_TABLE_HEADER_STATUS,
  CLAIMS_TABLE_ACTION_DETAIL_BUTTON,
  CLAIMS_TABLE_ACTION_DAMAGE_DESCRIPTION_BUTTON,
  CLAIMS_TABLE_HEADER_PLATE,
} = localizedStrings;

const tableHeaders = [
  CLAIMS_TABLE_HEADER_NUMBER,
  CLAIMS_TABLE_HEADER_CREATED_BY,
  CLAIMS_TABLE_HEADER_CREATED_ON,
  CLAIMS_TABLE_HEADER_PLATE,
  CLAIMS_TABLE_HEADER_NAME,
  CLAIMS_TABLE_HEADER_ASSIGNEE,
  CLAIMS_TABLE_HEADER_STATUS,
  CLAIMS_TABLE_HEADER_ACTION,
];

const PAGINATION_DISPLAY_LIMIT_DEFAULT = 10;

const ClaimTable = ({
  claims = [],
  count,
  currentPageIndex,
  onOpenModal,
  requestSort,
  sortConfig,
}) => {
  const dispatch = useDispatch();
  const { currentLocation } = useSelector(store => store.searchFilter);

  const navigateToClaimScreen = claimId => {
    navigate(`${CLAIM}/${claimId}`);
  };

  const getSortDirection = header => {
    if (header === CLAIMS_TABLE_HEADER_CREATED_ON) {
      return sortConfig.direction === 'descending' ? (
        <img src={arrowUp} className="arrow-style" alt="arrow-up" />
      ) : (
        <img src={arrowDown} className="arrow-style" alt="arrow-down" />
      );
    }
    return null;
  };

  const selectHeaderStyle = header =>
    header === CLAIMS_TABLE_HEADER_CREATED_ON ? 'header-pointer' : null;

  const renderPagination = () => {
    const numberOfPages = Math.ceil(count / PAGINATION_DISPLAY_LIMIT_DEFAULT);

    if (numberOfPages < 2) {
      return null;
    }

    const paginationDisplayValues = [];

    for (let i = 1; i <= numberOfPages; i += 1) {
      paginationDisplayValues.push(i.toString());
    }

    const currentPageIndexDisplayValue = (currentPageIndex + 1).toString();

    const onChangeLocationPage = newtLocation => {
      if (newtLocation.currentPageIndex !== currentLocation.currentPageIndex)
        dispatch(SearchActions.onChangeCurrentLocationPage(newtLocation));
    };
    return (
      <div className="pagination-container">
        <InformedSelect
          id="page-select"
          labelVisual=""
          initialValue={currentPageIndexDisplayValue}
          value={currentPageIndexDisplayValue}
          selectValues={paginationDisplayValues}
          onChange={event => {
            onChangeLocationPage({
              currentPageIndex: parseInt(event.target.value, 10) - 1,
            });
          }}
        />
      </div>
    );
  };

  /**
   * @param {*} action is the action for review or show damage description
   * @param {*} data is claimId | damageDescription
   */
  const onCLickActionButtons = (action, data) => {
    switch (action) {
      case CLAIMS_TABLE_ACTION_DETAIL_BUTTON:
        navigateToClaimScreen(data);
        break;
      case CLAIMS_TABLE_ACTION_DAMAGE_DESCRIPTION_BUTTON:
        onOpenModal(data);
        break;
      default:
        break;
    }
  };

  const renderTableRows = () =>
    claims.map((claim, index) => (
      <ClaimTableRow
        key={claim.id}
        claim={claim}
        isGrey={index % 2 === 0}
        onClick={onCLickActionButtons}
      />
    ));

  const renderTableHeaders = () => (
    <TableRow>
      {tableHeaders.map(header => (
        <TableCell
          key={header}
          type="colHead"
          dataHeader={header}
          onClick={() => requestSort(header)}
          className={selectHeaderStyle(header)}
        >
          <div className="header-style">
            <div className="div-arrow-style">{header}</div>
            <div>{getSortDirection(header)}</div>
          </div>
        </TableCell>
      ))}
    </TableRow>
  );

  return (
    <>
      <Panel>
        <Table>
          <tbody>
            {renderTableHeaders()}
            {renderTableRows()}
          </tbody>
        </Table>
      </Panel>
      {renderPagination()}
    </>
  );
};

ClaimTable.propTypes = {
  claims: PropTypes.arrayOf(claimPropType).isRequired,
  currentPageIndex: PropTypes.number.isRequired,
  count: PropTypes.number,
  onOpenModal: PropTypes.func.isRequired,
  requestSort: PropTypes.func.isRequired,
  sortConfig: PropTypes.shape({
    direction: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
};

ClaimTable.defaultProps = {
  count: 0,
};

export default ClaimTable;
