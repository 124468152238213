import constants from '@lmig-latam/adcl-common-lib/constants';

const {
  LANGUAGES: { EN },
} = constants;

const code = EN;

const strings = {
  BROWSER_TAB_TITLE: 'Reclamos – Liberty Seguros',
  LIBERTY_LOGO_IMAGE_ALT_TEXT: 'Liberty Seguros',
  VIGILADO_IMAGE_ALT_TEXT: 'VIGILADO SUPERINTENDENCIA BANCARIA DE COLOMBIA',

  APP_HEADER_TITLE: 'Integrated Claims Module',
  APP_HEADER_USER_SECTION_LOGOUT: 'Logout',
  OPERATING_COUNTRY_IMAGE_ALT_TEXT: 'English Flag',

  SIDEBAR_ITEM_0: 'Download images',
  SIDEBAR_ITEM_1: 'Claims',
  SIDEBAR_ITEM_2: 'Manage Account',
  SIDEBAR_ITEM_3: 'Change Password',
  SIDEBAR_ITEM_4: 'Create Claim',
  SIDEBAR_ITEM_5: 'Claim report',

  LOADING_TEXT: 'Loading',

  ERROR_MESSAGE_TITLE: 'Sorry, the process has already started or does not work correctly',
  ERROR_MESSAGE_SUBTITLE: 'If you need help, you can contact us through our service channels.',
  ERROR_MESSAGE_ACCESS_DENIED_TITLE: 'Access denied',
  ERROR_MESSAGE_ACCESS_DENIED_SUBTITLE: "You do not have access to this application's claims. Have you logged in with the correct account?",

  PAGE_NOT_FOUND_ERROR_MESSAGE: '404: Page not found!',

  MODAL_PRIMARY_BUTTON_LABEL: 'Continue',

  UTILS_AGE_SINCE_YEARS_AGO: '{interval} years ago',
  UTILS_AGE_SINCE_MONTHS_AGO: '{interval} months ago',
  UTILS_AGE_SINCE_DAYS_AGO: '{interval} days ago',
  UTILS_AGE_SINCE_HOURS_AGO: '{interval} hours ago',
  UTILS_AGE_SINCE_MINUTES_AGO: '{interval} minutes ago',
  UTILS_AGE_SINCE_SECONDS_AGO: '{interval} seconds ago',

  LOGIN_TITLE: 'Log In',
  LOGIN_SUBTITLE: 'Click the button below to sign in to ADCL',

  CLAIMS_TABLE_HEADER_NUMBER: 'CLAIM NO.',
  CLAIMS_TABLE_HEADER_NAME: 'NAME',
  CLAIMS_TABLE_HEADER_ACTION: 'ACTION',
  CLAIMS_TABLE_ACTION_DETAIL_BUTTON: 'Review',
  CLAIMS_TABLE_ACTION_DAMAGE_DESCRIPTION_BUTTON: 'Damage description',
  CLAIMS_TABLE_HEADER_CREATED_BY: 'CREATED BY',
  CLAIMS_TABLE_HEADER_CREATED_ON: 'CREATED ON',
  CLAIMS_TABLE_HEADER_ASSIGNEE: 'ASSIGNEE',
  CLAIMS_TABLE_ROW_PHOTO_ALERT_TOOLTIP: 'Claim with attached photographs',
  CLAIMS_TABLE_ROW_PHOTO_ALERT_ICON_ALT_TEXT: 'Photo alert',
  CLAIMS_TABLE_HEADER_STATUS: 'Status',
  CLAIMS_TABLE_HEADER_PLATE: 'PLATE',

  SEARCH_TITLE: 'Claims',
  SEARCH_SUBTITLE: 'Manages, updates and performs vehicle claims photographic waiting for your policy',
  SEARCH_LOADING_LABEL: 'Please wait...',

  SEARCH_BAR_TABS_PENDING_REVIEW: 'Pending for review',
  SEARCH_BAR_TABS_PENDING_APPROVAL: 'Pending approval',
  SEARCH_BAR_TABS_FINISHED: 'Completed',
  SEARCH_BAR_TABS_PENDING_PER_CUSTOMER: 'Pending customer action',
  SEARCH_BAR_INPUT_PLACEHOLDER: 'Search by number plate or identification...',

  SEARCH_NO_RESULTS_TITLE: "Oops, sorry we have not found what you're looking for",
  SEARCH_NO_RESULTS_SUBTITLE: 'Verify and try again',

  CLAIM_TITLE: 'Claim',
  CLAIM_SUBTITLE: 'Make the claim of the vehicle by means of the photographs sent by the client, do not forget the comments and attach the necessary supports',
  CLAIM_LOADING_LABEL: 'Please wait...',
  CLAIM_APPROVE_BUTTON: 'Approve',
  CLAIM_REJECT_BUTTON: 'Reject',
  CLAIM_RETURN_BUTTON: 'Return',
  CLAIM_CUSTOMER_RETAKE_BUTTON: 'Request a new photo shoot',
  CLAIM_SAVE_BUTTON: 'Save',
  CLAIM_SAVE_AND_EXIT_BUTTON: 'Save and exit',
  CLAIM_DELETE_BUTTON: 'Delete',

  CLAIM_DELETE_MODAL_TITLE: 'Are you sure?',
  CLAIM_DELETE_MODAL_SUBTITLE: 'This action cannot be undone.',
  CLAIM_DELETE_MODAL_CANCEL_BUTTON_LABEL: 'Cancel',
  CLAIM_DELETE_MODAL_CONFIRM_BUTTON_LABEL: 'Delete',
  CLAIM_DELETE_MODAL_MESSAGE_INPUT_PLACEHOLDER: 'Why is this claim being deleted? (Maximum 1000 characters)',
  CLAIM_DELETE_MODAL_TEXTAREA_TITLE: 'Please write your comments regarding this case',

  CLAIM_OVERRIDE_MODAL_TITLE: 'This claim has pending photos',
  CLAIM_OVERRIDE_MODAL_SUBTITLE: 'This action cannot be undone.',
  CLAIM_OVERRRIDE_MODAL_CANCEL_BUTTON_LABEL: 'Cancel',
  CLAIM_OVERRIDE_MODAL_MESSAGE_INPUT_PLACEHOLDER: 'Why is this claim being overriden, please provide as much detail as possible i.e. If photos have been aquired from other sources where are they stored etc. (Maximum 1000 characters)',
  CLAIM_OVERRIDE_MODAL_TEXTAREA_APPROVE_TITLE: 'Why is this claim being approved?',
  CLAIM_OVERRIDE_MODAL_TEXTAREA_REJECT_TITLE: 'Why is this claim being rejected?',

  CLAIM_OVERRIDE_COMMENT_TITLE: 'Claim was Completed via Override',
  CLAIM_OVERRIDE_AGENT_NAME: 'Overriding Agent Name:',

  CLAIM_SUMMARY_HEADER_1: 'Claim No.',
  CLAIM_SUMMARY_HEADER_2: 'Created By',
  CLAIM_SUMMARY_HEADER_3: 'Created On',
  CLAIM_SUMMARY_HEADER_4: 'Name of taker',
  CLAIM_SUMMARY_HEADER_5: 'Status',
  CLAIM_SUMMARY_HEADER_PLATE: 'Plate',

  CLAIM_VEHICLE_DETAILS_SELECT_DEFAULT: '- Please Select -',
  CLAIM_VEHICLE_DETAILS_VALIDATION_FIELD_EMPTY: 'field must not be empty',
  CLAIM_VEHICLE_DETAILS_VALIDATION_FIELD_MAX: 'field must not be more than {maxLength} characters',
  CLAIM_VEHICLE_DETAILS_VALIDATION_SELECT_EMPTY: 'please select a valid option from the dropdown list',
  CLAIM_VEHICLE_SUBMISSIONID_EXIST: 'the claim number already exist in the system.',

  CLAIM_VEHICLE_PHOTOS_NO_PHOTOS_MESSAGE: 'No photos availabe',
  CLAIM_VEHICLE_PHOTOS_TITLE: 'Photo Approval',
  CLAIM_VEHICLE_PHOTOS_SUBTITLE: 'Begin the claims process, verify that the photographs comply with the permitted standards to continue with the process',
  CLAIM_VEHICLE_PHOTOS_HEADER_2: 'Image description',
  CLAIM_VEHICLE_PHOTOS_HEADER_3: 'Upload date',
  CLAIM_VEHICLE_PHOTOS_HEADER_4: 'Actions',
  CLAIM_VEHICLE_PHOTOS_AI_BANNER: 'Note: You may see icons beside each image, we are testing automatic image detection to help you with the review process. This information is for guidance only and images must still be manually reviewed.',
  CLAIM_VEHICLE_PHOTOS_IMAGE_VEHICLE_TOOLTIP: 'We have detected a vehicle present in this image',
  CLAIM_VEHICLE_PHOTOS_IMAGE_PERSON_TOOLTIP: 'We have detected that a person may appear in this image',
  CLAIM_VEHICLE_PHOTOS_RADIO_BUTTON_ACCEPT: 'Accept',
  CLAIM_VEHICLE_PHOTOS_RADIO_BUTTON_REJECT: 'Retake',
  CLAIM_VEHICLE_PHOTOS_COMMENTS_SENT_SUCCESSFULLY: 'Photographs were returned, comments were successfully sente',
  CLAIM_VEHICLE_PHOTOS_VIEW_COMMENTS: 'See comments',
  CLAIM_VEHICLE_PHOTOS_INCORRECT_PHOTOS: 'Oops, apparently there are some incorrect photographs',
  CLAIM_VEHICLE_PHOTOS_MODAL_DOWNLOAD_IMAGE: 'Download image',
  CLAIM_VEHICLE_PHOTOS_MODAL_DOWNLOAD_ALL_IMAGE: 'Download all images',
  CLAIM_VEHICLE_PHOTOS_MODAL_DOWNLOADING_ALL_IMAGE: 'Downloading.. Please Wait',
  CLAIM_CUSTOMER_MESSAGES_TITLE: 'My comments',
  CLAIM_CUSTOMER_MESSAGES_BUTTON_LABEL: 'Close',

  CLAIM_NEW_CUSTOMER_MESSAGE_TITLE: 'Photo comments',
  CLAIM_NEW_CUSTOMER_MESSAGE_INPUT_PLACEHOLDER: 'Write your observations about the photographs (maximum 1000 characters)',
  CLAIM_NEW_CUSTOMER_MESSAGE_SEND_COMMENTS: 'Send message to the client',
  CLAIM_NEW_CUSTOMER_MESSAGE_LOADING: 'Please wait...',
  CLAIM_NEW_CUSTOMER_MESSAGE_SUCCESS_TITLE: 'Message sent to the customer',
  CLAIM_NEW_CUSTOMER_MESSAGE_CLOSE: 'Close',

  CLAIM_CUSTOMER_RESEND_NOTIFICATION_SUCCESS_TITLE: 'Success',
  CLAIM_CUSTOMER_RESEND_NOTIFICATION_FAIL_TITLE: 'Fail',
  CLAIM_CUSTOMER_RESEND_NOTIFICATION_CONFIRMATION_TITLE: 'Attention',
  CLAIM_CUSTOMER_RESEND_NOTIFICATION_SUCCESS_SUBTITLE: 'Email and SMS notifications have been resent to the customer',
  CLAIM_CUSTOMER_RESEND_NOTIFICATION_FAIL_SUBTITLE: 'Notifications failed to send',
  CLAIM_CUSTOMER_RESEND_NOTIFICATION_CONFIRMATION_SUBTITLE: 'Are you sure you want to send? Accepting will also save the claim information',
  CLAIM_CUSTOMER_RESEND_NOTIFICATION_CLOSE: 'Close',

  CLAIM_CUSTOMER_DETAILS_BUTTON_RESEND: 'Resend',
  CLAIM_CUSTOMER_DETAILS_BUTTON_SENDING: 'Sending...',

  CLAIM_SUPPORTING_DOCUMENTS_TITLE: 'Supports',
  CLAIM_SUPPORTING_DOCUMENTS_SUBTITLE: 'Attach and send the supporting items that you consider necessary for the claim, they can be screenshots, documents, among others.\nSupported formats (PDF, jpg, jpeg, png, bmp, doc, docx).',
  CLAIM_SUPPORTING_DOCUMENTS_INPUT_PLACEHOLDER: 'Drag files to attach, or ',
  CLAIM_SUPPORTING_DOCUMENTS_INPUT_BROWSE_LINK: 'browse',

  CLAIM_COMMENTS_TITLE: 'Your comments',
  CLAIM_COMMENTS_SUBTITLE: 'Do not forget to enter the comments you have about the claim',
  CLAIM_COMMENTS_INPUT_PLACEHOLDER: 'Write your observations (maximum 1000 characters)',

  CLAIM_DESCRIPTIONS_TITLE: 'Claim description',
  CLAIM_DESCRIPTIONS_INPUT_PLACEHOLDER: 'In this space the main details of the accident or damage to the vehicle will be provided.',

  CLAIM_STATUS_AGENT_REVIEW_DISPLAY_VALUE: 'Needs agent review',
  CLAIM_STATUS_AGENT_IN_PROGRESS_DISPLAY_VALUE: 'Agent review in progress',
  CLAIM_STATUS_AGENT_APPROVED_DISPLAY_VALUE: 'Approved',
  CLAIM_STATUS_AGENT_REJECTED_DISPLAY_VALUE: 'Rejected',
  CLAIM_STATUS_CUSTOMER_RETAKE_DISPLAY_VALUE: 'Customer needs to retake',
  CLAIM_STATUS_CUSTOMER_NEW_DISPLAY_VALUE: 'New claim',
  CLAIM_STATUS_CUSTOMER_IN_PROGRESS_DISPLAY_VALUE: 'Customer in progress',
  CLAIM_STATUS_EXPIRED_DISPLAY_VALUE: 'Expired',
  CLAIM_STATUS_DELETED_DISPLAY_VALUE: 'Deleted',

  CLAIM_ASSIGNEE_TITLE: 'Assigned:',
  CLAIM_ASSIGNEE_ASSIGN_TO_ME_BUTTON: 'Assign to me',
  CLAIM_DEFAULT_ASSIGNEE_DISPLAY_VALUE: 'UNASSIGNED',

  CLAIM_COMPLETE_ACCEPTED_TITLE: 'Photographic Claim Accepted',
  CLAIM_COMPLETE_ACCEPTED_SUBTITLE: 'Photos meet Claim requirements',
  CLAIM_COMPLETE_REJECTED_TITLE: 'Rejected claim',
  CLAIM_COMPLETE_REJECTED_SUBTITLE: 'The vehicle does not meet the basic requirements to obtain a policy',
  CLAIM_COMPLETE_BUTTON_LABEL: 'Return',

  CLAIM_PHOTO_VEHICLE_DAMAGE_ONE_DISPLAY_STRING: 'Photo of Damage - One',
  CLAIM_PHOTO_VEHICLE_DAMAGE_TWO_DISPLAY_STRING: 'Photo of Damage - Two',
  CLAIM_PHOTO_VEHICLE_DAMAGE_THREE_DISPLAY_STRING: 'Photo of Damage - Three',
  CLAIM_PHOTO_VEHICLE_DAMAGE_FOUR_DISPLAY_STRING: 'Photo of Damage - Four',
  CLAIM_PHOTO_VEHICLE_DAMAGE_FIVE_DISPLAY_STRING: 'Photo of Damage - Five',
  CLAIM_PHOTO_VEHICLE_NUMBER_PLATE_DISPLAY_STRING: 'Number plate',
  CLAIM_PHOTO_VEHICLE_FRONT_DISPLAY_STRING: 'Vehicle front',
  CLAIM_PHOTO_PROPERTY_CARD_FRONT_DISPLAY_STRING: 'Front of property card',
  CLAIM_PHOTO_PROPERTY_CARD_BACK_DISPLAY_STRING: 'Back of property card',
  CLAIM_PHOTO_VEHICLE_REAR_DISPLAY_STRING: 'Rear of the vehicle',
  CLAIM_PHOTO_VEHICLE_RIGHT_SIDE_DISPLAY_STRING: 'Right side',
  CLAIM_PHOTO_VEHICLE_LEFT_SIDE_DISPLAY_STRING: 'Left side',
  CLAIM_PHOTO_VEHICLE_DASH_DISPLAY_STRING: 'Milometer or dashboard',
  CLAIM_PHOTO_VEHICLE_CHASSIS_NUMBER_DISPLAY_STRING: 'Chassis number',

  CREATE_CLAIM_CUSTOMER_TITLE: 'Customer',
  CREATE_CLAIM_CUSTOMER_SUBTITLE: 'Enter customer information',
  CREATE_CLAIM_SUBMIT: 'Submit',
  CREATE_CLAIM_PREFILL: 'Prefill',
  CREATE_CLAIM_CREATION_ERROR: 'Error when creating claim',
  CREATE_CLAIM_CREATION_SUCCESS: 'Claim created successfully',
  CREATE_CLAIM_CREATION_CLOSE: 'Close',
  CREATE_CLAIM_CREATION_CONTINUE: 'Continue',
  CREATE_CLAIM_CREATION_COPY: 'Copy link',
  CREATE_CLAIM_INVALID_PHONE_NUMBER: 'Phone number invalid',

  REPORT_SUCCESS_MODAL_TITLE: 'Your report has been downloaded successfully',
  REPORT_SUCCESS_MODAL_BUTTON: 'Close',
  REPORT_TITLE: 'Reporte de inspecciones',
  REPORT_SUBTITLE: 'Generar un reporte detallado con toda información de tus inspecciones',
  REPORT_SUBMIT_BUTTON: 'Generate report',
  REPORT_FORM_SUBTITLE: 'Select the range of dates you want to generate your report',
  REPORT_FORM_START_DATE: 'Start date',
  REPORT_FORM_END_DATE: 'End date',
  REPORT_FORM_ERROR_MAX_RANGE: 'The requested timeframe must be 90 days or less',

  SEARCH_FILTER_BAR_WITH_PHOTOS: 'With photos',
};

export default { code, strings };
