import { GridRow, GridCol, Button } from '@lmig/lmds-react';
import { IconEmail } from '@lmig/lmds-react/icons';
import { Form } from 'informed';
import PropTypes from 'prop-types';
import React from 'react';
import { customerPropType } from '../../prop-types';
import { InformedAlphanumericInput, Panel, ErrorsList } from '..';
import { config } from './config';
import { verifyFieldValidation } from '../../utils';
import './styles.scss';
import localizedStrings from '../../utils/localizedStrings';

const { CLAIM_CUSTOMER_DETAILS_BUTTON_RESEND } = localizedStrings;

const CustomerDetails = ({
  customer,
  updateFieldValidation,
  updateCustomer,
  readOnly,
  showNotificationModal,
  resendDisabled,
}) => {
  const initialValueFormater = number => {
    if (number.includes('-')) {
      return number.split('-')[1];
    }
    if (number.slice(0, 3) === '593') {
      return number.slice(3);
    }
    return number.slice(2);
  };

  const getInformedInput = field => {
    const { fieldName, labelVisual } = field;

    const fieldProps = {
      field: fieldName,
      id: fieldName,
      className: 'customer-details-input',
      labelVisual,
      onChange: event => {
        updateCustomer({ ...customer, [fieldName]: event.target.value });
      },
      initialValue: 'cellPhoneNumber'.includes(field.fieldName)
        ? initialValueFormater(customer.cellPhoneNumber)
        : customer[fieldName],
      value: customer[fieldName],
      validate: value => verifyFieldValidation(value, field),
      validateOnChange: true,
      validateOnMount: true,
      validateOnBlur: true,
      disabled: readOnly,
    };

    return (
      <GridCol key={`vehicle-details-${fieldName}`}>
        <InformedAlphanumericInput {...fieldProps} />
      </GridCol>
    );
  };

  const handleFieldValidation = formState => {
    updateFieldValidation(Object.values(formState.errors));
  };

  return (
    <Form autoComplete="off" onChange={handleFieldValidation}>
      {({ formState }) => {
        const errors = Object.values(formState.errors);

        return (
          <Panel className="customer-details-section">
            <GridRow>
              {config.customer.map(field => getInformedInput(field))}
              <Button
                className="customer-details-resend-button"
                type="button"
                level="primary"
                onClick={showNotificationModal}
                disabled={resendDisabled}
              >
                <span>{CLAIM_CUSTOMER_DETAILS_BUTTON_RESEND}</span>

                <IconEmail
                  size="24"
                  className="customer-details-resend-button-icon"
                />
              </Button>
            </GridRow>
            <ErrorsList errors={errors} />
          </Panel>
        );
      }}
    </Form>
  );
};

CustomerDetails.propTypes = {
  customer: customerPropType.isRequired,
  updateCustomer: PropTypes.func.isRequired,
  updateFieldValidation: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  showNotificationModal: PropTypes.func.isRequired,
  resendDisabled: PropTypes.bool.isRequired,
};

CustomerDetails.defaultProps = {
  readOnly: false,
};

export default CustomerDetails;
