import Auth from '@aws-amplify/auth';
import constants from '@lmig-latam/adcl-common-lib/constants';
import { getApiEndpoint } from '../environmentsHelpers';
import clFlag from '../../../styles/images/country-flag-circle-images/cl.svg';
import coFlag from '../../../styles/images/country-flag-circle-images/co.svg';
import ecFlag from '../../../styles/images/country-flag-circle-images/ec.svg';

const {
  LANGUAGES: { CL },
} = constants;

export default {
  manageAccountUrl: 'https://eservices.lmig.com/EDA/enduser/Start.do',
  changePasswordUrl: 'https://eservices.lmig.com/EDA/enduser/ChangePassword.do',
  countryCode: CL,
  language: CL,
  enableCreateClaimPrefill: false,
  displayImageAnalysis: true,
  cl: {
    vigiladoLogoDisplayed: false,
    countryFlag: clFlag,
  },
  co: {
    vigiladoLogoDisplayed: true,
    countryFlag: coFlag,
  },
  ec: {
    vigiladoLogoDisplayed: false,
    countryFlag: ecFlag,
  },

  // AWS Amplify Config
  awsConfig: {
    Auth: {
      // Amazon Cognito Identity Pool ID
      identityPoolId: 'us-east-1:97248fbd-1e2d-4921-b63f-81339b05337e',
      // Amazon Cognito Region
      region: 'us-east-1',
      // Amazon Cognito User Pool ID
      userPoolId: 'us-east-1_tQkZm0uQ9',
      // Amazon Cognito Web Client ID
      userPoolWebClientId: 'o22nqf22fi6ff22sd022jfag0',
      // Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,
      // oauth
      oauth: {
        domain: 'adcl-agent-prod.auth.us-east-1.amazoncognito.com',
        scope: ['profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: `${window.location.origin}/app/`,
        redirectSignOut:
          'https://lmidp.libertymutual.com:443/idp/startSLO.ping',
        responseType: 'code',
      },
    },
    Analytics: {
      disabled: true,
    },
    API: {
      endpoints: [
        {
          name: 'prod-adcl-agent-apis',
          endpoint: getApiEndpoint(),
          region: 'us-east-1',
          custom_header: async () => ({
            jwttoken: (await Auth.currentSession()).getIdToken().getJwtToken(),
          }),
        },
      ],
    },
  },
};
