import { FIELD_NAMES } from '@lmig-latam/adcl-common-lib/constants';
import PropTypes from 'prop-types';
import {
  addCustomerMessage,
  assignClaim,
  createClaim,
  deleteClaim,
  deleteDocument,
  getClaim,
  getClaimCount,
  getClaims,
  getCustomerMessages,
  getDocuments,
  getPhotos,
  getPhotosZip,
  getReport,
  resendCustomerNotifications,
  updateClaim,
  updateClaimStatus,
  updatePhotos,
  uploadDocument,
} from '../api';
import { getCountryCode } from '../config/environments/environmentsHelpers';
import { ERROR_GENERIC } from '../utils/navigationConstants';
import { navigate } from '../utils/NavigationUtils';

const {
  SOURCE_APPLICATION_ID,
  SOURCE_APPLICATION_ENDPOINT,
  COUNTRY_CODE,
} = FIELD_NAMES;

export default {
  PropertyTypes: PropTypes.shape({
    addCustomerMessage: PropTypes.func.isRequired,
    assignClaim: PropTypes.func.isRequired,
    createClaim: PropTypes.func.isRequired,
    deleteClaim: PropTypes.func.isRequired,
    deleteDocument: PropTypes.func.isRequired,
    getClaim: PropTypes.func.isRequired,
    getClaimCount: PropTypes.func.isRequired,
    getClaims: PropTypes.func.isRequired,
    getCustomerMessages: PropTypes.func.isRequired,
    getDocuments: PropTypes.func.isRequired,
    getPhotos: PropTypes.func.isRequired,
    getPhotosZip: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
    resendCustomerNotifications: PropTypes.func.isRequired,
    updateClaim: PropTypes.func.isRequired,
    updateClaimStatus: PropTypes.func.isRequired,
    updatePhotos: PropTypes.func.isRequired,
    uploadDocument: PropTypes.func.isRequired,
  }),

  createClaim(claim) {
    const newClaim = {
      ...claim,
      [SOURCE_APPLICATION_ID]: 'ADCL_AGENT',
      [SOURCE_APPLICATION_ENDPOINT]: 'N/A',
      [COUNTRY_CODE]: getCountryCode(),
    };
    return async () => createClaim(newClaim);
  },

  getClaims(searchParams) {
    return async () => getClaims(searchParams);
  },

  getReport(searchParams = {}) {
    return async () => getReport(searchParams);
  },

  getClaim(claimId) {
    return async () => getClaim(claimId);
  },

  updateClaim(claim) {
    return async () => updateClaim(claim);
  },

  updateClaimStatus(claimId, status, agentId, overrideComment, rejectedPhotos) {
    const body = { status, rejectedPhotos };

    if (overrideComment) {
      body.agentOverrideComment = { agentId, overrideComment };
    }

    return async () => updateClaimStatus(claimId, body);
  },

  getPhotos(claimId, params) {
    return async () => getPhotos(claimId, params);
  },

  getPhotosZip(id) {
    return async () => getPhotosZip(id);
  },

  updatePhotos(claimId, photos) {
    return async () => updatePhotos(claimId, photos);
  },

  getDocuments(id) {
    return async () => {
      const response = await getDocuments(id);

      try {
        return response.documents.map(document => ({
          ...document,
          url: document.url,
        }));
      } catch (error) {
        navigate(ERROR_GENERIC);
        return [];
      }
    };
  },

  uploadDocument(claimId, document) {
    return async () => uploadDocument(claimId, document);
  },

  deleteDocument(claimId, document) {
    return async () => deleteDocument(claimId, document);
  },

  deleteClaim(claimId, agentComments) {
    return async () => deleteClaim(claimId, agentComments);
  },

  getCustomerMessages(id) {
    return async () => {
      const response = await getCustomerMessages(id);

      try {
        return response.customerMessages;
      } catch (error) {
        navigate(ERROR_GENERIC);
        return [];
      }
    };
  },

  addCustomerMessage(claimId, customerMessage) {
    return async () => addCustomerMessage(claimId, customerMessage);
  },

  getClaimCount() {
    return async () => getClaimCount();
  },

  resendCustomerNotifications(claimId) {
    return async () => resendCustomerNotifications(claimId);
  },

  assignClaim(claimId, name) {
    return async () => assignClaim(claimId, name);
  },
};
