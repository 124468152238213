import constants from '@lmig-latam/adcl-common-lib/constants';
import PropTypes from 'prop-types';

// Shared PropTypes For Environment
// Note: We maintain propsDefition as a separate object because we have an important
// unit test which depends on being able to inspect the number of attributes in this object.
// The prop-types type is locked down so we cannot do this directly on a PropTypes.shape object
const propsDefinition = {
  manageAccountUrl: PropTypes.string.isRequired,
  changePasswordUrl: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  enableCreateClaimPrefill: PropTypes.bool.isRequired,
  displayImageAnalysis: PropTypes.bool.isRequired,
  cl: PropTypes.shape({
    vigiladoLogoDisplayed: PropTypes.bool.isRequired,
  }).isRequired,
  co: PropTypes.shape({
    vigiladoLogoDisplayed: PropTypes.bool.isRequired,
  }).isRequired,
  ec: PropTypes.shape({
    vigiladoLogoDisplayed: PropTypes.bool.isRequired,
  }).isRequired,
  awsConfig: PropTypes.shape({
    Auth: PropTypes.shape({
      identityPoolId: PropTypes.string.isRequired,
      region: PropTypes.string.isRequired,
      userPoolId: PropTypes.string.isRequired,
      userPoolWebClientId: PropTypes.string.isRequired,
      mandatorySignIn: PropTypes.bool.isRequired,
      oauth: PropTypes.shape({
        domain: PropTypes.string.isRequired,
        scope: PropTypes.arrayOf(PropTypes.string).isRequired,
        redirectSignIn: PropTypes.string.isRequired,
        redirectSignOut: PropTypes.string.isRequired,
        responseType: PropTypes.string.isRequired,
      }).isRequired,
    }),
    Analytics: PropTypes.shape({
      disabled: PropTypes.bool.isRequired,
    }),
    API: PropTypes.shape({
      endpoints: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          endpoint: PropTypes.string.isRequired,
          region: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  }).isRequired,
};

const PropertyTypes = PropTypes.shape(propsDefinition);

const doesArrayContainCurrentUrl = array =>
  array.some(
    string =>
      window.location.origin.toLowerCase().indexOf(string.toLowerCase()) > -1,
  );

const isNonProduction = () => doesArrayContainCurrentUrl(['-nonprod']);

const isDevelopment = () => doesArrayContainCurrentUrl(['-dev']);

const isLocalhost = () =>
  doesArrayContainCurrentUrl([
    'localhost',
    'libp45p-18218cf' /* Michael Wallace */,
    'libp45p-364076m' /* Mark McKim */,
    'libp45p-19079d5' /* Jason Priestley */,
  ]);

const isProduction = () =>
  !isNonProduction() && !isDevelopment() && !isLocalhost();

const getCountryCode = () =>
  isLocalhost()
    ? constants.LANGUAGES.CL
    : window.location.host
        .split('.')
        .pop()
        .toLowerCase();

const getApiEndpoint = () => {
  const endpointParts = ['https://adcl-agent-api'];

  if (isLocalhost() || isDevelopment()) {
    endpointParts.push('-dev');
  } else if (isNonProduction()) {
    endpointParts.push('-nonprod');
  }

  endpointParts.push(constants.DOMAINS[getCountryCode()]);

  if (isLocalhost() || isDevelopment()) {
    endpointParts.push('/dev');
  } else if (isNonProduction()) {
    endpointParts.push('/nonprod');
  } else {
    endpointParts.push('/prod');
  }

  endpointParts.push('/api');

  return endpointParts.join('');
};

export {
  getApiEndpoint,
  getCountryCode,
  isLocalhost,
  isDevelopment,
  isNonProduction,
  isProduction,
  PropertyTypes,
  propsDefinition,
};
