import {
  ONCHANGE_CURRENT_LOCATION_PAGE,
  ONCHANGE_SEARCH_TEXT_CLAIMS,
  SELECT_STEP_FILTER_SEARCH,
  SET_COUNTS_CLAIMS,
  RESET_FILTER_STEPS,
} from './constants';

const setStepFilter = filter => ({
  type: SELECT_STEP_FILTER_SEARCH,
  payload: { filter },
});

const resetFilterSteps = () => ({
  type: RESET_FILTER_STEPS,
});

const setCountClaims = count => ({
  type: SET_COUNTS_CLAIMS,
  payload: { count },
});

const onChangeSearchTextClaims = text => ({
  type: ONCHANGE_SEARCH_TEXT_CLAIMS,
  payload: { text },
});

const onChangeCurrentLocationPage = location => ({
  type: ONCHANGE_CURRENT_LOCATION_PAGE,
  payload: { location },
});

export default {
  setStepFilter,
  resetFilterSteps,
  setCountClaims,
  onChangeSearchTextClaims,
  onChangeCurrentLocationPage,
};
