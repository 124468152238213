import { LOG_LEVELS } from './logger';

const { ERROR, INFO, WARN } = LOG_LEVELS;

export default {
  AAAPI010: {
    code: 'AAAPI010',
    level: INFO,
    message: 'Fuzzy match triggered',
  },

  AADIS110: {
    code: 'AADIS110',
    level: ERROR,
    message: 'Cannot find display string for photo id',
  },

  AAISD010: {
    code: 'AAISD010',
    level: ERROR,
    message: 'Cannot find display string for claim status',
  },

  AANAV010: {
    code: 'AANAV010',
    level: INFO,
    message: 'User has navigated',
  },

  AAEXL010: {
    code: 'AAEXL010',
    level: WARN,
    message: 'Number of fields and number of headers in Excel doc do not match',
  },

  CALAN110: {
    code: 'CALAN110',
    level: ERROR,
    message: 'Error selecting language',
  },
};
