import { Caption, Disclaimer, Heading } from '@lmig/lmds-react';
import { IconClose } from '@lmig/lmds-react/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Panel } from '..';
import { documentPropType, newDocumentPropType } from '../../prop-types';
import localizedStrings from '../../utils/localizedStrings';
import Arrow from './Arrow.svg';
import './styles.scss';

const {
  CLAIM_SUPPORTING_DOCUMENTS_TITLE,
  CLAIM_SUPPORTING_DOCUMENTS_SUBTITLE,
  CLAIM_SUPPORTING_DOCUMENTS_INPUT_PLACEHOLDER,
  CLAIM_SUPPORTING_DOCUMENTS_INPUT_BROWSE_LINK,
} = localizedStrings;

// Accepted File Types
// Note: This should be kept in sync with the "accept" prop of the file input
// further down in this component
const validFileTypes = /\.(pdf|jpg|jpeg|png|bmp|doc|docx)$/i;

const SupportingDocuments = ({
  addNewDocuments,
  deleteDocument,
  deleteNewDocument,
  newDocuments,
  documents,
  readOnly,
}) => {
  const inputRef = useRef();
  const [highlighted, setHighlighted] = useState(false);

  const preventDefaultFieldBehavior = event => {
    event.preventDefault();
    event.stopPropagation();
  };
  const isValidFileType = fileName => fileName.match(validFileTypes);

  const addNewDocumentsHandler = async newFiles => {
    const newDocumentsToAdd = await Promise.all(
      Array.from(newFiles)
        .filter(newFile => isValidFileType(newFile.name))
        .map(
          newFile =>
            new Promise(resolve => {
              const reader = new FileReader();
              reader.readAsDataURL(newFile);
              reader.onload = event => {
                resolve({
                  name: newFile.name,
                  data: event.target.result,
                });
              };
            }),
        ),
    );
    addNewDocuments(newDocumentsToAdd);
    setHighlighted(false);
  };

  const onChangeFilePickerHandler = event => {
    addNewDocumentsHandler(event.target.files);
  };

  const handleDocumentDragDropHandler = event => {
    preventDefaultFieldBehavior(event);
    addNewDocumentsHandler(event.dataTransfer.files);
  };

  const handleDocumentDragEnterHandler = event => {
    preventDefaultFieldBehavior(event);
    setHighlighted(true);
  };

  const handleDocumentDragOverHandler = event => {
    preventDefaultFieldBehavior(event);
    setHighlighted(true);
  };

  const handleDocumentDragLeaveHandler = event => {
    preventDefaultFieldBehavior(event);
    setHighlighted(false);
  };

  const deleteDocumentHandler = document => {
    deleteDocument(document);
  };

  const deleteNewDocumentHandler = document => {
    deleteNewDocument(document);
    inputRef.current.value = '';
  };

  return (
    <Panel className="supporting-documents">
      <Heading type="h3-light" className="auto-left-right-margin">
        {CLAIM_SUPPORTING_DOCUMENTS_TITLE}
      </Heading>
      {!readOnly && (
        <Disclaimer className="supporting-documents-subtitle">
          {CLAIM_SUPPORTING_DOCUMENTS_SUBTITLE}
        </Disclaimer>
      )}
      {!readOnly && (
        <div
          className={classNames(
            'supporting-documents-upload-box',
            'auto-left-right-margin',
            { 'supporting-documents-upload-box-highlighted': highlighted },
          )}
          onDrop={handleDocumentDragDropHandler}
          onDragEnter={handleDocumentDragEnterHandler}
          onDragOver={handleDocumentDragOverHandler}
          onDragLeave={handleDocumentDragLeaveHandler}
        >
          <span>
            <img
              src={Arrow}
              alt={
                CLAIM_SUPPORTING_DOCUMENTS_INPUT_PLACEHOLDER +
                CLAIM_SUPPORTING_DOCUMENTS_INPUT_BROWSE_LINK
              }
            />
            {CLAIM_SUPPORTING_DOCUMENTS_INPUT_PLACEHOLDER}
            <input
              type="file"
              id="fileElem"
              multiple
              accept=".pdf,.jpg,.jpeg,.png,.bmp,.doc,.docx"
              className="supporting-documents-upload-box-file-picker"
              onChange={onChangeFilePickerHandler}
              ref={inputRef}
            />
            <label className="button" htmlFor="fileElem">
              <span className="supporting-documents-upload-box-browse-link">
                {CLAIM_SUPPORTING_DOCUMENTS_INPUT_BROWSE_LINK}
              </span>
            </label>
          </span>
        </div>
      )}
      <div className="supporting-documents-files">
        {documents.map(document => (
          <div className="supporting-documents-file" key={document.name}>
            <a href={document.url}>
              <Caption>{document.name}</Caption>
            </a>
            {!readOnly && (
              <span
                role="button"
                onClick={() => {
                  deleteDocumentHandler(document);
                }}
              >
                <IconClose color="inverse" size="16" />
              </span>
            )}
          </div>
        ))}
        {newDocuments.map(document => (
          <div className="supporting-documents-file" key={document.name}>
            <a href={document.data} download={document.name}>
              <Caption>{document.name}</Caption>
            </a>
            <span
              role="button"
              onClick={() => {
                deleteNewDocumentHandler(document);
              }}
            >
              <IconClose color="inverse" size="16" />
            </span>
          </div>
        ))}
      </div>
    </Panel>
  );
};

SupportingDocuments.propTypes = {
  documents: PropTypes.arrayOf(documentPropType).isRequired,
  newDocuments: PropTypes.arrayOf(newDocumentPropType).isRequired,
  deleteDocument: PropTypes.func.isRequired,
  deleteNewDocument: PropTypes.func.isRequired,
  addNewDocuments: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

SupportingDocuments.defaultProps = {
  readOnly: false,
};

export default SupportingDocuments;
