import { Heading, Disclaimer } from '@lmig/lmds-react';
import { IconAutoDamage } from '@lmig/lmds-react/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { Panel, CircleImage } from '..';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const {
  CLAIM_DESCRIPTIONS_TITLE,
  CLAIM_DESCRIPTIONS_INPUT_PLACEHOLDER,
} = localizedStrings;

const DamageDescriptionField = ({ damageDescription }) => (
  <Panel className="descriptions">
    <CircleImage size="small">
      <IconAutoDamage size="64" />
    </CircleImage>
    <Heading type="h3-light" className="auto-left-right-margin">
      {CLAIM_DESCRIPTIONS_TITLE}
    </Heading>
    <div className="description-claim-list">
      <Disclaimer className="description-claim-box">
        {damageDescription || CLAIM_DESCRIPTIONS_INPUT_PLACEHOLDER}
      </Disclaimer>
    </div>
  </Panel>
);

DamageDescriptionField.propTypes = {
  damageDescription: PropTypes.string.isRequired,
};

export default DamageDescriptionField;
