import {
  BodyText,
  Caption,
  Disclaimer,
  Heading,
  Notification,
  Table,
  TableCell,
  TableRow,
} from '@lmig/lmds-react';
import { IconChat, IconDownload, IconInfo } from '@lmig/lmds-react/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { CircleImage, CustomerMessages, Panel, VehiclePhotoRow } from '..';
import { customerMessagePropType, photoPropType } from '../../prop-types';
import { photoStatuses } from '../../utils/constants';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const {
  CLAIM_VEHICLE_PHOTOS_NO_PHOTOS_MESSAGE,
  CLAIM_VEHICLE_PHOTOS_TITLE,
  CLAIM_VEHICLE_PHOTOS_SUBTITLE,
  CLAIM_VEHICLE_PHOTOS_HEADER_2,
  CLAIM_VEHICLE_PHOTOS_HEADER_3,
  CLAIM_VEHICLE_PHOTOS_HEADER_4,
  CLAIM_VEHICLE_PHOTOS_AI_BANNER,
  CLAIM_VEHICLE_PHOTOS_COMMENTS_SENT_SUCCESSFULLY,
  CLAIM_VEHICLE_PHOTOS_VIEW_COMMENTS,
  CLAIM_VEHICLE_PHOTOS_INCORRECT_PHOTOS,
  CLAIM_VEHICLE_PHOTOS_MODAL_DOWNLOADING_ALL_IMAGE,
  CLAIM_VEHICLE_PHOTOS_MODAL_DOWNLOAD_ALL_IMAGE,
} = localizedStrings;

const { REJECTED } = photoStatuses;

const VehiclePhotos = ({
  photos,
  downloadPhotosZip,
  updatePhotos,
  photosZipDownloading,
  customerMessages,
  newCustomerMessages,
  readOnly,
  isRead,
  displayImageAnalysis,
}) => {
  const [
    customerMessagesModalDisplayed,
    setCustomerMessagesModalDisplayed,
  ] = useState(false);

  const showCustomerMessagesModal = () => {
    setCustomerMessagesModalDisplayed(true);
  };

  const hideCustomerMessagesModal = () => {
    setCustomerMessagesModalDisplayed(false);
  };

  const updatePhoto = updatedPhoto => {
    const updatedPhotos = photos.map(photo =>
      photo.name === updatedPhoto.name ? updatedPhoto : photo,
    );
    updatePhotos(updatedPhotos);
  };

  const renderPhotosTable = anyRejectedPhotos => {
    const hasExistingMessages =
      customerMessages.length + newCustomerMessages.length > 0;
    const numberOfRejectedPhotos = photos.filter(
      photo => photo.status === REJECTED,
    ).length;
    return (
      <>
        <Table className="vehicle-photos-table adcl-box-shadow">
          <tbody>
            <TableRow className="vehicle-photos-headers">
              <TableCell type="colHead" />
              <TableCell type="colHead" />
              <TableCell
                type="colHead"
                dataHeader={CLAIM_VEHICLE_PHOTOS_HEADER_2}
              >
                {CLAIM_VEHICLE_PHOTOS_HEADER_2}
              </TableCell>
              <TableCell
                type="colHead"
                dataHeader={CLAIM_VEHICLE_PHOTOS_HEADER_3}
              >
                {CLAIM_VEHICLE_PHOTOS_HEADER_3}
              </TableCell>
              {!isRead && (
                <TableCell
                  type="colHead"
                  dataHeader={CLAIM_VEHICLE_PHOTOS_HEADER_4}
                >
                  {CLAIM_VEHICLE_PHOTOS_HEADER_4}
                </TableCell>
              )}
            </TableRow>
            {photos.map((photo, index) => (
              <VehiclePhotoRow
                key={photo.url}
                photo={photo}
                updatePhoto={updatePhoto}
                isGrey={index % 2 === 0}
                readOnly={readOnly}
                isRead={isRead}
                displayImageAnalysis={displayImageAnalysis}
              />
            ))}
            {hasExistingMessages && (
              <TableRow
                className={classNames({
                  'adcl-table-row-grey': photos.length % 2 === 0,
                })}
              >
                <td
                  colSpan="5"
                  className="lm-Table-cell vehicle-photos-existing-customer-messages"
                >
                  <div>
                    <BodyText>
                      {`\u2713 ${numberOfRejectedPhotos} ${CLAIM_VEHICLE_PHOTOS_COMMENTS_SENT_SUCCESSFULLY}`}
                    </BodyText>
                    <div role="button" onClick={showCustomerMessagesModal}>
                      <BodyText>{CLAIM_VEHICLE_PHOTOS_VIEW_COMMENTS}</BodyText>
                      <CircleImage
                        size="small"
                        className="vehicle-photos-existing-customer-messages-icon"
                      >
                        <IconChat size="32" />
                      </CircleImage>
                    </div>
                  </div>
                </td>
              </TableRow>
            )}
          </tbody>
        </Table>
        {anyRejectedPhotos && (
          <div className="vehicle-photos-incorrect-photos-tooltip">
            <Caption>
              <IconInfo size="16" />
              {CLAIM_VEHICLE_PHOTOS_INCORRECT_PHOTOS}
            </Caption>
            <i />
          </div>
        )}
      </>
    );
  };

  const renderNoPhotosMessage = () => (
    <BodyText className="vehicle-photos-no-photos-message auto-left-right-margin">
      {CLAIM_VEHICLE_PHOTOS_NO_PHOTOS_MESSAGE}
    </BodyText>
  );

  const renderDownloadAll = () => (
    <div
      onClick={photosZipDownloading ? null : downloadPhotosZip}
      className="download-all-link"
      role="button"
    >
      <BodyText>
        {photosZipDownloading
          ? CLAIM_VEHICLE_PHOTOS_MODAL_DOWNLOADING_ALL_IMAGE
          : CLAIM_VEHICLE_PHOTOS_MODAL_DOWNLOAD_ALL_IMAGE}
      </BodyText>
      <CircleImage size="small">
        <IconDownload size="16" />
      </CircleImage>
    </div>
  );

  const anyRejectedPhotos = photos.some(photo => photo.status === REJECTED);

  const photosNonZero = photos.length > 0;

  return (
    <Panel className="vehicle-photos">
      {photos.length !== 0 && renderDownloadAll()}
      <Heading type="h3-light" className="auto-left-right-margin header">
        {CLAIM_VEHICLE_PHOTOS_TITLE}
      </Heading>
      <Disclaimer>{CLAIM_VEHICLE_PHOTOS_SUBTITLE}</Disclaimer>
      {displayImageAnalysis && photosNonZero && (
        <Notification className="vehicle-photos-ai-banner">
          {CLAIM_VEHICLE_PHOTOS_AI_BANNER}
        </Notification>
      )}
      {photos.length > 0
        ? renderPhotosTable(anyRejectedPhotos)
        : renderNoPhotosMessage()}
      <CustomerMessages
        isOpen={customerMessagesModalDisplayed}
        closeButtonHandler={hideCustomerMessagesModal}
        customerMessages={customerMessages}
        newCustomerMessages={newCustomerMessages}
      />
    </Panel>
  );
};
VehiclePhotos.propTypes = {
  photos: PropTypes.arrayOf(photoPropType).isRequired,
  downloadPhotosZip: PropTypes.func.isRequired,
  photosZipDownloading: PropTypes.bool.isRequired,
  updatePhotos: PropTypes.func.isRequired,
  customerMessages: PropTypes.arrayOf(customerMessagePropType).isRequired,
  newCustomerMessages: PropTypes.arrayOf(customerMessagePropType).isRequired,
  readOnly: PropTypes.bool,
  isRead: PropTypes.bool,
  displayImageAnalysis: PropTypes.bool.isRequired,
};

VehiclePhotos.defaultProps = {
  readOnly: false,
  isRead: false,
};

export default VehiclePhotos;
