import { FIELD_NAMES } from '@lmig-latam/adcl-common-lib/constants';
import { getCountryConfig } from '@lmig-latam/adcl-common-lib/countries';
import { getCountryCode } from '../../config/environments/environmentsHelpers';

const { CUSTOMER, CELL_PHONE_NUMBER, EMAIL } = FIELD_NAMES;

const FIELD_CONFIG = getCountryConfig(getCountryCode()).getFieldConfig();

export const fieldConfig = {
  [CUSTOMER]: [
    FIELD_CONFIG[CUSTOMER][CELL_PHONE_NUMBER],
    FIELD_CONFIG[CUSTOMER][EMAIL],
  ],
};

Object.keys(fieldConfig).forEach(fieldSection => {
  fieldConfig[fieldSection] = fieldConfig[fieldSection].filter(
    field => typeof field !== 'undefined',
  );
});

export const config = fieldConfig;

export default config;
