import { getCountryCodesUpperCase } from '@lmig-latam/adcl-common-lib/utils';
import { DEFAULT_ASIGNEE } from '@lmig-latam/adcl-common-lib/constants';
import { utils } from '@lmig-latam/adlib-ui';
import { SettingsActions } from '../actions';
import { store } from '../common/store';
import {
  SIDEBAR_COOKIE_EXPIRY_HOURS,
  SIDEBAR_COOKIE_NAME,
  sortedKeys,
} from './constants';
import localizedStrings from './localizedStrings';
import { logcodes, logger } from './logger';

const { formatString } = utils;

const {
  UTILS_AGE_SINCE_YEARS_AGO,
  UTILS_AGE_SINCE_MONTHS_AGO,
  UTILS_AGE_SINCE_DAYS_AGO,
  UTILS_AGE_SINCE_HOURS_AGO,
  UTILS_AGE_SINCE_MINUTES_AGO,
  UTILS_AGE_SINCE_SECONDS_AGO,
  CLAIM_VEHICLE_DETAILS_VALIDATION_FIELD_EMPTY,
  CLAIM_VEHICLE_DETAILS_VALIDATION_FIELD_MAX,
  CLAIM_VEHICLE_DETAILS_VALIDATION_SELECT_EMPTY,
  CLAIM_DEFAULT_ASSIGNEE_DISPLAY_VALUE,
} = localizedStrings;

export const convertFileToDataURL = file => {
  const reader = new FileReader();
  const readDataPromise = new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
  reader.readAsDataURL(file);

  return readDataPromise;
};

export const getAgeSince = seconds => {
  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return formatString(UTILS_AGE_SINCE_YEARS_AGO, {
      interval,
    });
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return formatString(UTILS_AGE_SINCE_MONTHS_AGO, {
      interval,
    });
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return formatString(UTILS_AGE_SINCE_DAYS_AGO, {
      interval,
    });
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return formatString(UTILS_AGE_SINCE_HOURS_AGO, {
      interval,
    });
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return formatString(UTILS_AGE_SINCE_MINUTES_AGO, {
      interval,
    });
  }
  return formatString(UTILS_AGE_SINCE_SECONDS_AGO, {
    interval,
  });
};

export const arraysEqual = (a, b, ignoreOrder) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  if (ignoreOrder) {
    a.sort();
    b.sort();
  }

  for (let i = 0; i < a.length; i += 1) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

// Taken from https://www.w3schools.com/js/js_cookies.asp
export const getCookie = cookieName => {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i += 1) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return '';
};

// Taken from https://www.w3schools.com/js/js_cookies.asp
// Note: If no expiry is provided, the cookie will expire with the session
export const setCookie = (cookieName, cookieValue, expiryInHours = null) => {
  let expires = '';
  if (expiryInHours) {
    const now = new Date();
    now.setTime(now.getTime() + expiryInHours * 60 * 60 * 1000);
    expires = `expires=${now.toUTCString()}`;
  }
  document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
};

// Taken from https://stackoverflow.com/a/2144404
export const deleteCookie = cookieName => {
  if (getCookie(cookieName)) {
    document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
};

export const verifyFieldValidation = (
  fieldValue,
  { labelVisual, maxLength, fieldType, fieldTypeError, required },
) => {
  let errorMessage; // Should default to undefined if validation passes

  if (!fieldValue && !required) {
    return errorMessage;
  }

  if (!fieldValue) {
    errorMessage = `${labelVisual}: ${CLAIM_VEHICLE_DETAILS_VALIDATION_FIELD_EMPTY}`;
  } else if (`${fieldValue}`.length > maxLength) {
    errorMessage = `${labelVisual}: ${formatString(
      CLAIM_VEHICLE_DETAILS_VALIDATION_FIELD_MAX,
      { maxLength },
    )}`;
  } else if (fieldType && !fieldValue.match(fieldType)) {
    errorMessage = `${labelVisual}: ${fieldTypeError}`;
  }

  return errorMessage;
};

export const verifySelectValidation = (
  fieldValue,
  { labelVisual, required },
) => {
  let errorMessage; // Should default to undefined if validation passes

  if (!fieldValue && required) {
    errorMessage = `${labelVisual}: ${CLAIM_VEHICLE_DETAILS_VALIDATION_SELECT_EMPTY}`;
  }

  return errorMessage;
};

export const getClaimStatusDisplayName = claimStatus => {
  let displayString =
    localizedStrings[`CLAIM_STATUS_${claimStatus}_DISPLAY_VALUE`];

  if (!displayString) {
    logger.log(logcodes.AAISD010, { status: claimStatus });
    displayString = claimStatus;
  }

  return displayString;
};

export const getAssigneeDisplayName = assignee => {
  let displayString = assignee;

  // Needed for backwards compatibility of claims that were not created with an assignee
  if (!assignee || assignee === DEFAULT_ASIGNEE) {
    displayString = CLAIM_DEFAULT_ASSIGNEE_DISPLAY_VALUE;
  }

  return displayString;
};

export const photoSorter = photos => {
  const result = [];
  let originalArray = photos;
  sortedKeys.forEach(key => {
    let found = false;
    originalArray = originalArray.filter(item => {
      if (!found && item.name === key) {
        result.push(item);
        found = true;
        return false;
      }
      return true;
    });
  });
  result.push(...originalArray);

  return result;
};

export const saveSidebarState = () => {
  const {
    settings: { sidebarOpen },
  } = store.getState();
  setCookie(SIDEBAR_COOKIE_NAME, !sidebarOpen, SIDEBAR_COOKIE_EXPIRY_HOURS);
};

export const restoreSidebarState = () => {
  const sidebarCookieVal = getCookie(SIDEBAR_COOKIE_NAME);
  if (sidebarCookieVal === 'false') {
    store.dispatch(SettingsActions.toggleSidebar());
  }
};

export const isReviewer = user =>
  user.attributes['custom:groups'].includes('ADCL_Claim_Review_') ||
  user.attributes['custom:groups'].includes('ADCL_Claim_Admin_');

export const isCreator = user =>
  user.attributes['custom:groups'].includes('ADCL_Claim_Create_') ||
  user.attributes['custom:groups'].includes('ADCL_Claim_Admin_');

export const isRead = user =>
  user.attributes['custom:groups'].includes('ADCL_Claim_Read_');

export const isAdmin = user =>
  user.attributes['custom:groups'].includes('ADCL_Claim_Admin_');

export const getAllowedCountries = user =>
  getCountryCodesUpperCase().filter(
    country =>
      user.attributes['custom:groups'].includes(
        `ADCL_Claim_Review_${country}`,
      ) ||
      user.attributes['custom:groups'].includes(
        `ADCL_Claim_Create_${country}`,
      ) ||
      user.attributes['custom:groups'].includes(
        `ADCL_Claim_Admin_${country}`,
      ) ||
      user.attributes['custom:groups'].includes(
        'ADCL_Claim_Admin_All_Countries',
      ),
  );

const startYear = 1900;
const endYear = new Date().getFullYear();

// Fill an array with sequential values
// https://davidwalsh.name/fill-array-javascript
export const YEAR_SELECT_OPTIONS = Array(endYear - startYear + 1)
  .fill()
  .map((item, index) => `${startYear + index}`);

export const formatISOString = isoString => {
  if (!Date.parse(isoString)) {
    return isoString;
  }

  const date = new Date(isoString);

  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  const year = date.getFullYear().toString();
  let hour = date.getHours().toString();
  let minute = date.getMinutes().toString();

  day = day.length === 1 ? `0${day}` : day;
  month = month.length === 1 ? `0${month}` : month;
  hour = hour.length === 1 ? `0${hour}` : hour;
  minute = minute.length === 1 ? `0${minute}` : minute;

  return `${day}/${month}/${year} ${hour}:${minute}`;
};

export const downloadFileFromUrl = (url, fileName) => {
  const download = document.createElement('a');
  download.href = url;
  download.setAttribute('download', fileName);
  download.click();
};
