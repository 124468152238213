import localizedStrings from '../utils/localizedStrings';

const {
  CLAIM_PHOTO_VEHICLE_NUMBER_PLATE_DISPLAY_STRING,
  CLAIM_PHOTO_VEHICLE_FRONT_DISPLAY_STRING,
  CLAIM_PHOTO_VEHICLE_REAR_DISPLAY_STRING,
  CLAIM_PHOTO_VEHICLE_RIGHT_SIDE_DISPLAY_STRING,
  CLAIM_PHOTO_VEHICLE_LEFT_SIDE_DISPLAY_STRING,
  CLAIM_PHOTO_VEHICLE_DASH_DISPLAY_STRING,
  CLAIM_PHOTO_VEHICLE_CHASSIS_NUMBER_DISPLAY_STRING,
  CLAIM_PHOTO_VEHICLE_DAMAGE_ONE_DISPLAY_STRING,
  CLAIM_PHOTO_VEHICLE_DAMAGE_TWO_DISPLAY_STRING,
  CLAIM_PHOTO_VEHICLE_DAMAGE_THREE_DISPLAY_STRING,
  CLAIM_PHOTO_VEHICLE_DAMAGE_FOUR_DISPLAY_STRING,
  CLAIM_PHOTO_VEHICLE_DAMAGE_FIVE_DISPLAY_STRING,
  CLAIM_PHOTO_PROPERTY_CARD_FRONT_DISPLAY_STRING,
  CLAIM_PHOTO_PROPERTY_CARD_BACK_DISPLAY_STRING,
} = localizedStrings;

export const photoDisplayStringMapping = {
  // Required photos
  CLAIM_PHOTO_VEHICLE_NUMBER_PLATE: CLAIM_PHOTO_VEHICLE_NUMBER_PLATE_DISPLAY_STRING,
  CLAIM_PHOTO_VEHICLE_FRONT: CLAIM_PHOTO_VEHICLE_FRONT_DISPLAY_STRING,
  CLAIM_PHOTO_VEHICLE_REAR: CLAIM_PHOTO_VEHICLE_REAR_DISPLAY_STRING,
  CLAIM_PHOTO_VEHICLE_RIGHT_SIDE: CLAIM_PHOTO_VEHICLE_RIGHT_SIDE_DISPLAY_STRING,
  CLAIM_PHOTO_VEHICLE_LEFT_SIDE: CLAIM_PHOTO_VEHICLE_LEFT_SIDE_DISPLAY_STRING,
  CLAIM_PHOTO_VEHICLE_DASH: CLAIM_PHOTO_VEHICLE_DASH_DISPLAY_STRING,
  CLAIM_PHOTO_VEHICLE_CHASSIS_NUMBER: CLAIM_PHOTO_VEHICLE_CHASSIS_NUMBER_DISPLAY_STRING,
  CLAIM_PHOTO_VEHICLE_DAMAGE_ONE: CLAIM_PHOTO_VEHICLE_DAMAGE_ONE_DISPLAY_STRING,
  CLAIM_PHOTO_VEHICLE_DAMAGE_TWO: CLAIM_PHOTO_VEHICLE_DAMAGE_TWO_DISPLAY_STRING,
  CLAIM_PHOTO_VEHICLE_DAMAGE_THREE: CLAIM_PHOTO_VEHICLE_DAMAGE_THREE_DISPLAY_STRING,
  CLAIM_PHOTO_VEHICLE_DAMAGE_FOUR: CLAIM_PHOTO_VEHICLE_DAMAGE_FOUR_DISPLAY_STRING,
  CLAIM_PHOTO_VEHICLE_DAMAGE_FIVE: CLAIM_PHOTO_VEHICLE_DAMAGE_FIVE_DISPLAY_STRING,
  CLAIM_PHOTO_PROPERTY_CARD_FRONT: CLAIM_PHOTO_PROPERTY_CARD_FRONT_DISPLAY_STRING,
  CLAIM_PHOTO_PROPERTY_CARD_BACK: CLAIM_PHOTO_PROPERTY_CARD_BACK_DISPLAY_STRING,
};

export const getPhotoDisplayStringFromId = id =>
  // ADCL allows for arbitrary photo IDs so we need to use the raw ID if no mapping is found
  photoDisplayStringMapping[id] ? photoDisplayStringMapping[id] : id;

export default getPhotoDisplayStringFromId;
