import { Header as LibertyMutualHeader } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppHeaderUserSection } from '..';
import { UserActions } from '../../actions';
import { userPropType } from '../../prop-types';
import libertyLogo from '../../styles/images/logo-liberty.svg';
import localizedStrings from '../../utils/localizedStrings';

import './styles.scss';

const { LIBERTY_LOGO_IMAGE_ALT_TEXT, APP_HEADER_TITLE } = localizedStrings;

const AppHeader = ({ loggedIn, user, userActions }) => (
  <LibertyMutualHeader className="app-header">
    <div>
      <img
        src={libertyLogo}
        className="app-header-image"
        alt={LIBERTY_LOGO_IMAGE_ALT_TEXT}
      />
      <span className="app-header-title">{APP_HEADER_TITLE}</span>
    </div>
    {loggedIn && (
      <AppHeaderUserSection user={user} onLogout={userActions.logout} />
    )}
  </LibertyMutualHeader>
);

AppHeader.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  user: userPropType.isRequired,
  userActions: UserActions.PropertyTypes.isRequired,
};

const mapStateToProps = ({ settings: { loggedIn }, user }) => ({
  loggedIn,
  user,
});

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(UserActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
