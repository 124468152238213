import React from 'react';
import './styles.scss';

const BackIcon = () => (
  <svg className="claim-buttons-back-icon">
    <path d="M6.6,1.5l-1-1.2L0.7,5.8c-0.5,0.5-0.4,1.5,0,1.9l4.9,5l1-1.1l-4.1-4h11.1V5.8h-11L6.6,1.5z" />
  </svg>
);

export default BackIcon;
