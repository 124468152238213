import { Caption, Table, TableCell, TableRow } from '@lmig/lmds-react';
import React from 'react';
import { Panel } from '..';
import { claimPropType } from '../../prop-types';
import { formatISOString, getClaimStatusDisplayName } from '../../utils';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const {
  CLAIM_SUMMARY_HEADER_1,
  CLAIM_SUMMARY_HEADER_2,
  CLAIM_SUMMARY_HEADER_3,
  CLAIM_SUMMARY_HEADER_4,
  CLAIM_SUMMARY_HEADER_5,
  CLAIM_SUMMARY_HEADER_PLATE,
} = localizedStrings;

const NOT_APPLICABLE = 'N/A';

const ClaimSummary = ({
  claim: {
    status,
    customer: { firstName, lastName },
    submissionId,
    creationDate = NOT_APPLICABLE,
    createdBy = NOT_APPLICABLE,
    plate,
  },
}) => {
  const onSubmissionID = () =>
    submissionId === NOT_APPLICABLE || !submissionId
      ? ''
      : submissionId.toUpperCase();

  return (
    <Panel className="claim-summary">
      <Table>
        <tbody>
          <TableRow className="claim-summary-headers">
            <TableCell type="colHead">{CLAIM_SUMMARY_HEADER_1}</TableCell>
            <TableCell type="colHead">{CLAIM_SUMMARY_HEADER_2}</TableCell>
            <TableCell type="colHead">{CLAIM_SUMMARY_HEADER_3}</TableCell>
            <TableCell type="colHead">{CLAIM_SUMMARY_HEADER_PLATE}</TableCell>
            <TableCell type="colHead">{CLAIM_SUMMARY_HEADER_4}</TableCell>
            <TableCell type="colHead">{CLAIM_SUMMARY_HEADER_5}</TableCell>
          </TableRow>
          <TableRow className="claim-summary-content">
            <TableCell>
              <Caption>{onSubmissionID()}</Caption>
            </TableCell>
            <TableCell>
              <Caption>{createdBy}</Caption>
            </TableCell>
            <TableCell>
              <Caption>{formatISOString(creationDate)}</Caption>
            </TableCell>
            <TableCell>
              <Caption>{plate || ''}</Caption>
            </TableCell>
            <TableCell>
              <Caption>{[firstName, lastName].join(' ')}</Caption>
            </TableCell>
            <TableCell>
              <Caption>{getClaimStatusDisplayName(status)}</Caption>
            </TableCell>
          </TableRow>
        </tbody>
      </Table>
    </Panel>
  );
};

ClaimSummary.propTypes = {
  claim: claimPropType.isRequired,
};

export default ClaimSummary;
