import React from 'react';
import { useSelector } from 'react-redux';
import { Panel, MenuFilter, StatusFilterBar } from '..';
import InputSearch from './InputSearch';
import './styles.scss';

const SearchBar = () => {
  const { filterSelected } = useSelector(store => store.searchFilter);

  return (
    <>
      <MenuFilter />

      <Panel className="adcl-search-bar-panel">
        <InputSearch />
      </Panel>

      {filterSelected && filterSelected.statuses.length > 1 && (
        <StatusFilterBar />
      )}
    </>
  );
};

export default SearchBar;
