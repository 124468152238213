import { Button } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React from 'react';
import { BackIcon, SaveAndExitIcon, SaveIcon } from '.';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const {
  CLAIM_APPROVE_BUTTON,
  CLAIM_REJECT_BUTTON,
  CLAIM_RETURN_BUTTON,
  CLAIM_CUSTOMER_RETAKE_BUTTON,
  CLAIM_SAVE_BUTTON,
  CLAIM_SAVE_AND_EXIT_BUTTON,
  CLAIM_DELETE_BUTTON,
} = localizedStrings;

const ClaimButtons = ({
  showRetakeButton,
  handleReturn,
  handleSave,
  handleSaveAndExit,
  disableSave,
  disableApproveRejectDelete,
  handleDelete,
  showDeleteButton,
  handleReject,
  showCustomerRetakeModal,
  handleApprove,
  showOnlyReturnButton,
}) => {
  const renderTextButtons = () => (
    <>
      <Button
        type="button"
        level="secondary"
        onClick={handleReturn}
        className="claim-text-button"
      >
        <BackIcon />
        {CLAIM_RETURN_BUTTON}
      </Button>
      {!showOnlyReturnButton && (
        <Button
          type="button"
          level="secondary"
          onClick={handleSave}
          className="claim-text-button"
          disabled={disableSave}
        >
          <SaveIcon />
          {CLAIM_SAVE_BUTTON}
        </Button>
      )}

      {!showOnlyReturnButton && (
        <Button
          type="button"
          level="secondary"
          onClick={handleSaveAndExit}
          className="claim-text-button"
          disabled={disableSave}
        >
          <SaveAndExitIcon />
          {CLAIM_SAVE_AND_EXIT_BUTTON}
        </Button>
      )}
    </>
  );

  const renderApproveRejectButtons = () => (
    <>
      {!showOnlyReturnButton && (
        <Button
          type="button"
          level="secondary"
          onClick={handleReject}
          disabled={disableApproveRejectDelete}
        >
          {CLAIM_REJECT_BUTTON}
        </Button>
      )}
      {!showOnlyReturnButton && (
        <Button
          type="button"
          onClick={handleApprove}
          disabled={disableApproveRejectDelete}
        >
          {CLAIM_APPROVE_BUTTON}
        </Button>
      )}
    </>
  );

  const renderRetakeButton = () =>
    !showOnlyReturnButton && (
      <Button
        type="button"
        onClick={showCustomerRetakeModal}
        disabled={!showRetakeButton}
      >
        {CLAIM_CUSTOMER_RETAKE_BUTTON}
      </Button>
    );

  const renderDeleteButton = () =>
    (!showOnlyReturnButton || showDeleteButton) && (
      <Button
        type="button"
        className={disableApproveRejectDelete ? '' : 'claim-delete-button'}
        onClick={handleDelete}
        disabled={disableApproveRejectDelete}
      >
        {CLAIM_DELETE_BUTTON}
      </Button>
    );

  return (
    <div className="claim-action-buttons">
      {renderTextButtons()}
      {showRetakeButton ? renderRetakeButton() : renderApproveRejectButtons()}
      {renderDeleteButton()}
    </div>
  );
};

ClaimButtons.propTypes = {
  disableApproveRejectDelete: PropTypes.bool.isRequired,
  handleReturn: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleSaveAndExit: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
  handleApprove: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  showRetakeButton: PropTypes.bool.isRequired,
  showCustomerRetakeModal: PropTypes.func.isRequired,
  disableSave: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  showOnlyReturnButton: PropTypes.bool.isRequired,
};

ClaimButtons.defaultProps = {
  disableSave: false,
  showDeleteButton: false,
  handleDelete: () => {},
};

export default ClaimButtons;
