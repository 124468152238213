import React from 'react';
import {
  CreateClaim,
  GenericError,
  Claim,
  ClaimComplete,
  PageNotFound,
  Search,
  Report,
} from '..';
import {
  CREATE_CLAIM,
  ERROR_GENERIC,
  CLAIM,
  CLAIM_COMPLETE,
  SEARCH,
  REPORT,
} from '../../utils/navigationConstants';

export default [
  {
    exact: true,
    path: CREATE_CLAIM,
    render: props => <CreateClaim {...props} />,
    isCreatorRoute: true,
    isReviewerRoute: false,
  },
  {
    exact: true,
    path: SEARCH,
    render: props => <Search {...props} />,
    isCreatorRoute: false,
    isReviewerRoute: true,
  },
  {
    exact: true,
    path: `${CLAIM}/:claimId`,
    render: props => <Claim {...props} />,
    isCreatorRoute: false,
    isReviewerRoute: true,
  },
  {
    exact: true,
    path: CLAIM_COMPLETE,
    render: props => <ClaimComplete {...props} />,
    isCreatorRoute: false,
    isReviewerRoute: true,
  },
  {
    exact: true,
    path: REPORT,
    render: props => <Report {...props} />,
    isCreatorRoute: false,
    isReviewerRoute: true,
  },
  {
    exact: true,
    path: ERROR_GENERIC,
    render: props => <GenericError {...props} />,
    isCreatorRoute: true,
    isReviewerRoute: true,
  },
  {
    render: props => <PageNotFound {...props} />,
    isCreatorRoute: true,
    isReviewerRoute: true,
  },
];
