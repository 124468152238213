import constants from '@lmig-latam/adcl-common-lib/constants';

const {
  LANGUAGES: { CO },
} = constants;

const code = CO;

const strings = {
  BROWSER_TAB_TITLE: 'Siniestros  – Liberty Seguros',
  LIBERTY_LOGO_IMAGE_ALT_TEXT: 'Liberty Seguros',
  VIGILADO_IMAGE_ALT_TEXT: 'VIGILADO SUPERINTENDENCIA FINANCIERA DE COLOMBIA',

  APP_HEADER_TITLE: 'Módulo Integrado de Siniestros',
  APP_HEADER_USER_SECTION_LOGOUT: 'Salir',
  OPERATING_COUNTRY_IMAGE_ALT_TEXT: 'Bandera colombiana',

  SIDEBAR_ITEM_0: 'Descargar imágenes',
  SIDEBAR_ITEM_1: 'Siniestros',
  SIDEBAR_ITEM_2: 'Administrar cuenta',
  SIDEBAR_ITEM_3: 'Cambia la contraseña',
  SIDEBAR_ITEM_4: 'Crear inspección de siniestro',
  SIDEBAR_ITEM_5: 'Reporte de inspecciones',

  LOADING_TEXT: 'Cargando',

  ERROR_MESSAGE_TITLE: 'Lo sentimos, el proceso ya fue iniciado o no funciona correctamente',
  ERROR_MESSAGE_SUBTITLE: 'Si necesitas ayuda, puedes contáctarnos a través de nuestros canales de atención.',
  ERROR_MESSAGE_ACCESS_DENIED_TITLE: 'Acceso denegado',
  ERROR_MESSAGE_ACCESS_DENIED_SUBTITLE: 'No tiene acceso a las inspecciones de esta aplicación. ¿Has iniciado sesión con la cuenta correcta?',

  PAGE_NOT_FOUND_ERROR_MESSAGE: '404: ¡Página no encontrada!',

  MODAL_PRIMARY_BUTTON_LABEL: 'Continuar',

  UTILS_AGE_SINCE_YEARS_AGO: 'Hace {interval} años',
  UTILS_AGE_SINCE_MONTHS_AGO: 'Hace {interval} meses',
  UTILS_AGE_SINCE_DAYS_AGO: 'Hace {interval} días',
  UTILS_AGE_SINCE_HOURS_AGO: 'Hace {interval} horas',
  UTILS_AGE_SINCE_MINUTES_AGO: 'Hace {interval} minutos',
  UTILS_AGE_SINCE_SECONDS_AGO: 'Hace {interval} segundos',

  LOGIN_TITLE: 'Iniciar sesión',
  LOGIN_SUBTITLE: 'Haga clic en el botón de abajo para iniciar sesión en ADCL',

  CLAIMS_TABLE_HEADER_NUMBER: 'NO.SINIESTRO',
  CLAIMS_TABLE_HEADER_NAME: 'CLIENTE',
  CLAIMS_TABLE_HEADER_ACTION: 'ACCIÓN',
  CLAIMS_TABLE_ACTION_DETAIL_BUTTON: 'Revisar',
  CLAIMS_TABLE_ACTION_DAMAGE_DESCRIPTION_BUTTON: 'Descripción del daño',
  CLAIMS_TABLE_HEADER_CREATED_BY: 'AGENTE',
  CLAIMS_TABLE_HEADER_CREATED_ON: 'FECHA DE CREACIÓN',
  CLAIMS_TABLE_HEADER_ASSIGNEE: 'ASIGNADO',
  CLAIMS_TABLE_ROW_PHOTO_ALERT_TOOLTIP: 'Inspección con fotografías adjuntas',
  CLAIMS_TABLE_ROW_PHOTO_ALERT_ICON_ALT_TEXT: 'Alerta de foto',
  CLAIMS_TABLE_HEADER_STATUS: 'Estado',
  CLAIMS_TABLE_HEADER_PLATE: 'PLACA',

  SEARCH_TITLE: 'Siniestro',
  SEARCH_SUBTITLE: 'Gestiona, actualiza y realiza la inspección de siniestros para vehículos que esperan por su póliza',
  SEARCH_BAR_TABS_PENDING_REVIEW: 'Pendientes por revisión',
  SEARCH_BAR_TABS_PENDING_APPROVAL: 'Pendientes de aprobación',
  SEARCH_BAR_TABS_FINISHED: 'Finalizadas',
  SEARCH_BAR_TABS_PENDING_PER_CUSTOMER: 'Pendiente por cliente',
  SEARCH_BAR_INPUT_PLACEHOLDER: 'Busca por placa o identificación...',

  SEARCH_NO_RESULTS_TITLE: 'Ups, lo sentimos no hemos encontrado lo que buscas',
  SEARCH_NO_RESULTS_SUBTITLE: 'Verifica e intenta nuevamente',
  SEARCH_LOADING_LABEL: 'Por favor, espera...',

  CLAIM_TITLE: 'Siniestro',
  CLAIM_SUBTITLE: 'Realiza la inspección de siniestro del vehículo por medio de las fotografías enviadas por el cliente, no olvides los comentarios y adjuntar los soportes necesarios',
  CLAIM_LOADING_LABEL: 'Por favor, espera...',
  CLAIM_APPROVE_BUTTON: 'Aprobar',
  CLAIM_REJECT_BUTTON: 'Rechazar',
  CLAIM_RETURN_BUTTON: 'Volver',
  CLAIM_CUSTOMER_RETAKE_BUTTON: 'Solicitar nueva toma de fotografías',
  CLAIM_SAVE_BUTTON: 'Guardar',
  CLAIM_SAVE_AND_EXIT_BUTTON: 'Guardar y salir',
  CLAIM_DELETE_BUTTON: 'Eliminar',

  CLAIM_DELETE_MODAL_TITLE: '¿Estás seguro de elminar?',
  CLAIM_DELETE_MODAL_SUBTITLE: 'Esta acción no se puede deshacer.',
  CLAIM_DELETE_MODAL_CANCEL_BUTTON_LABEL: 'Cancelar',
  CLAIM_DELETE_MODAL_CONFIRM_BUTTON_LABEL: 'Eliminar',
  CLAIM_DELETE_MODAL_MESSAGE_INPUT_PLACEHOLDER: '¿Por qué se elimina esta inspección? (Máximo 1000 caracteres)',
  CLAIM_DELETE_MODAL_TEXTAREA_TITLE: 'Por favor escribe tus comentarios respecto a este caso:',

  CLAIM_OVERRIDE_MODAL_TITLE: 'Este reclamo tiene fotografías pendientes',
  CLAIM_OVERRIDE_MODAL_SUBTITLE: 'Esta acción no se puede deshacer.',
  CLAIM_OVERRRIDE_MODAL_CANCEL_BUTTON_LABEL: 'Cancelar',
  CLAIM_OVERRIDE_MODAL_MESSAGE_INPUT_PLACEHOLDER: 'Por favor escribe tus comentarios (Máximo 1000 caracteres)',
  CLAIM_OVERRIDE_MODAL_TEXTAREA_APPROVE_TITLE: '¿Por qué se está aprobando este reclamo?',
  CLAIM_OVERRIDE_MODAL_TEXTAREA_REJECT_TITLE: '¿Por qué se está rechazando este recalmo?',

  CLAIM_OVERRIDE_COMMENT_TITLE: 'La reclamo se finalizó con fotografías faltantes',
  CLAIM_OVERRIDE_AGENT_NAME: 'Nombre de usuario que aprueba:',

  CLAIM_SUMMARY_HEADER_1: 'No. Siniestro',
  CLAIM_SUMMARY_HEADER_2: 'Creado por',
  CLAIM_SUMMARY_HEADER_3: 'Fecha de creación',
  CLAIM_SUMMARY_HEADER_4: 'Nombre del Tomador',
  CLAIM_SUMMARY_HEADER_5: 'Estado',
  CLAIM_SUMMARY_HEADER_PLATE: 'Placa',

  CLAIM_VEHICLE_DETAILS_SELECT_DEFAULT: '- Seleccione -',
  CLAIM_VEHICLE_DETAILS_VALIDATION_FIELD_EMPTY: 'el campo no debe estar vacío ',
  CLAIM_VEHICLE_DETAILS_VALIDATION_FIELD_MAX: 'este campo no debe superar {maxLength} caracteres',
  CLAIM_VEHICLE_DETAILS_VALIDATION_SELECT_EMPTY: 'por favor selecciona una opción válida de la lista desplegable',
  CLAIM_VEHICLE_SUBMISSIONID_EXIST: 'el número de siniestro ya existe en el sistema.',

  CLAIM_VEHICLE_PHOTOS_NO_PHOTOS_MESSAGE: 'No hay fotos disponibles',
  CLAIM_VEHICLE_PHOTOS_TITLE: 'Aprobación de fotos',
  CLAIM_VEHICLE_PHOTOS_SUBTITLE: 'Comienza el proceso de inspección de siniestro, verifica que las fotografías cumplan con los requisitos para continuar con el proceso',
  CLAIM_VEHICLE_PHOTOS_HEADER_2: 'Descripción de la imagen',
  CLAIM_VEHICLE_PHOTOS_HEADER_3: 'Fecha de carga',
  CLAIM_VEHICLE_PHOTOS_HEADER_4: 'Acciones',
  CLAIM_VEHICLE_PHOTOS_AI_BANNER: 'Nota: es posible que observes íconos al costado de cada imagen, estamos probando la detección automática de imágenes para ayudarte con el proceso de revisión. Esta información es solo para orientación y las imágenes aún deben revisarse manualmente.',
  CLAIM_VEHICLE_PHOTOS_IMAGE_VEHICLE_TOOLTIP: 'Hemos detectado un vehículo presente en esta imagen',
  CLAIM_VEHICLE_PHOTOS_IMAGE_PERSON_TOOLTIP: 'Hemos detectado que una persona puede aparecer en esta imagen',
  CLAIM_VEHICLE_PHOTOS_RADIO_BUTTON_ACCEPT: 'Aceptar',
  CLAIM_VEHICLE_PHOTOS_RADIO_BUTTON_REJECT: 'Solicitar de nuevo',
  CLAIM_VEHICLE_PHOTOS_COMMENTS_SENT_SUCCESSFULLY: 'Fotografías fueron devueltas, los comentarios fueron enviados exitosamente',
  CLAIM_VEHICLE_PHOTOS_VIEW_COMMENTS: 'Ver comentarios',
  CLAIM_VEHICLE_PHOTOS_INCORRECT_PHOTOS: 'Ups, al parecer hay algunas fotografías incorrectas',
  CLAIM_VEHICLE_PHOTOS_MODAL_DOWNLOAD_IMAGE: 'Descargar imagen',
  CLAIM_VEHICLE_PHOTOS_MODAL_DOWNLOAD_ALL_IMAGE: 'Descargar imágenes',
  CLAIM_VEHICLE_PHOTOS_MODAL_DOWNLOADING_ALL_IMAGE: 'Descargando... por favor espera',

  CLAIM_CUSTOMER_MESSAGES_TITLE: 'Mis comentarios',
  CLAIM_CUSTOMER_MESSAGES_BUTTON_LABEL: 'Cerrar',

  CLAIM_NEW_CUSTOMER_MESSAGE_TITLE: 'Comentarios de fotografías',
  CLAIM_NEW_CUSTOMER_MESSAGE_INPUT_PLACEHOLDER: 'Escribe tus observaciones sobre las fotografías (máximo 1000 caracteres)',
  CLAIM_NEW_CUSTOMER_MESSAGE_SEND_COMMENTS: 'Enviar mensaje al cliente',
  CLAIM_NEW_CUSTOMER_MESSAGE_LOADING: 'Por favor, espera...',
  CLAIM_NEW_CUSTOMER_MESSAGE_SUCCESS_TITLE: 'Mensaje enviado al cliente',
  CLAIM_NEW_CUSTOMER_MESSAGE_CLOSE: 'Cerrar',

  CLAIM_CUSTOMER_RESEND_NOTIFICATION_SUCCESS_TITLE: 'Envíado con éxito',
  CLAIM_CUSTOMER_RESEND_NOTIFICATION_FAIL_TITLE: 'Error en el envío',
  CLAIM_CUSTOMER_RESEND_NOTIFICATION_CONFIRMATION_TITLE: 'Atención',
  CLAIM_CUSTOMER_RESEND_NOTIFICATION_SUCCESS_SUBTITLE: 'El Email y SMS fueron enviados al usuario',
  CLAIM_CUSTOMER_RESEND_NOTIFICATION_FAIL_SUBTITLE: 'El envío al usuario no pudo realizarse, por favor intenta de nuevo',
  CLAIM_CUSTOMER_RESEND_NOTIFICATION_CONFIRMATION_SUBTITLE: '¿Estás seguro que deseas reenviar? Al aceptar, se guardará la información del siniestro.',
  CLAIM_CUSTOMER_RESEND_NOTIFICATION_CLOSE: 'Cerrar',

  CLAIM_CUSTOMER_DETAILS_BUTTON_RESEND: 'Re-enviar',
  CLAIM_CUSTOMER_DETAILS_BUTTON_SENDING: 'Enviando...',

  CLAIM_SUPPORTING_DOCUMENTS_TITLE: 'Soportes',
  CLAIM_SUPPORTING_DOCUMENTS_SUBTITLE: 'Adjunta y envía los soportes que consideres necesarios para la revisión del siniestro, pueden ser pantallazos, documentos, entre otros.\nFormatos soportados (PDF, jpg, jpeg, png, bmp, doc, docx).',
  CLAIM_SUPPORTING_DOCUMENTS_INPUT_PLACEHOLDER: 'Arrastre archivos para adjuntar, o ',
  CLAIM_SUPPORTING_DOCUMENTS_INPUT_BROWSE_LINK: 'buscar',

  CLAIM_COMMENTS_TITLE: 'Tus comentarios',
  CLAIM_COMMENTS_SUBTITLE: 'No olvides ingresar los comentarios que tienes sobre el siniestro',
  CLAIM_COMMENTS_INPUT_PLACEHOLDER: 'Escribe tus observaciones (máximo 1000 caracteres)',

  CLAIM_DESCRIPTIONS_TITLE: 'Descripción del Siniestro',
  CLAIM_DESCRIPTIONS_INPUT_PLACEHOLDER: 'En este espacio se dispondrán los detalles principales del siniestro o daños al vehículo.',

  CLAIM_STATUS_AGENT_REVIEW_DISPLAY_VALUE: 'Pendiente revisión',
  CLAIM_STATUS_AGENT_IN_PROGRESS_DISPLAY_VALUE: 'Pendiente aprobación',
  CLAIM_STATUS_AGENT_APPROVED_DISPLAY_VALUE: 'Finalizada Aprobada',
  CLAIM_STATUS_AGENT_REJECTED_DISPLAY_VALUE: 'Finalizada rechazada',
  CLAIM_STATUS_CUSTOMER_RETAKE_DISPLAY_VALUE: 'Pendiente respuesta cliente',
  CLAIM_STATUS_CUSTOMER_NEW_DISPLAY_VALUE: 'No iniciada',
  CLAIM_STATUS_CUSTOMER_IN_PROGRESS_DISPLAY_VALUE: 'En proceso',
  CLAIM_STATUS_EXPIRED_DISPLAY_VALUE: 'Finalizada vencida',
  CLAIM_STATUS_DELETED_DISPLAY_VALUE: 'Finalizada eliminada',

  CLAIM_ASSIGNEE_TITLE: 'Asignado:',
  CLAIM_ASSIGNEE_ASSIGN_TO_ME_BUTTON: 'Asignarme',
  CLAIM_DEFAULT_ASSIGNEE_DISPLAY_VALUE: 'SIN ASIGNAR',

  CLAIM_COMPLETE_ACCEPTED_TITLE: 'Inspección Fotográfica Aceptada',
  CLAIM_COMPLETE_ACCEPTED_SUBTITLE: 'Las fotos cumplen con los requisitos de inspección',
  CLAIM_COMPLETE_REJECTED_TITLE: 'Siniestro rechazado',
  CLAIM_COMPLETE_REJECTED_SUBTITLE: 'El vehículo no cumple con los requisitos básicos para obtener una póliza',
  CLAIM_COMPLETE_BUTTON_LABEL: 'Volver a la bandeja',

  CLAIM_PHOTO_VEHICLE_DAMAGE_ONE_DISPLAY_STRING: 'Imagen Zona Afectada - Uno',
  CLAIM_PHOTO_VEHICLE_DAMAGE_TWO_DISPLAY_STRING: 'Imagen Zona Afectada - Dos',
  CLAIM_PHOTO_VEHICLE_DAMAGE_THREE_DISPLAY_STRING: 'Imagen Zona Afectada - Tres',
  CLAIM_PHOTO_VEHICLE_DAMAGE_FOUR_DISPLAY_STRING: 'Imagen Zona Afectada - Cuatro',
  CLAIM_PHOTO_VEHICLE_DAMAGE_FIVE_DISPLAY_STRING: 'Imagen Zona Afectada - Cinco',
  CLAIM_PHOTO_VEHICLE_NUMBER_PLATE_DISPLAY_STRING: 'Placa',
  CLAIM_PHOTO_VEHICLE_FRONT_DISPLAY_STRING: 'Parte delantera del vehículo',
  CLAIM_PHOTO_PROPERTY_CARD_FRONT_DISPLAY_STRING: 'Parte frontal de la tarjeta de propiedad',
  CLAIM_PHOTO_PROPERTY_CARD_BACK_DISPLAY_STRING: 'Parte trasera de la tarjeta de propiedad',
  CLAIM_PHOTO_VEHICLE_REAR_DISPLAY_STRING: 'Parte trasera del vehículo',
  CLAIM_PHOTO_VEHICLE_RIGHT_SIDE_DISPLAY_STRING: 'Lateral derecha',
  CLAIM_PHOTO_VEHICLE_LEFT_SIDE_DISPLAY_STRING: 'Lateral izquierda',
  CLAIM_PHOTO_VEHICLE_DASH_DISPLAY_STRING: 'Tablero de instrumentos',
  CLAIM_PHOTO_VEHICLE_CHASSIS_NUMBER_DISPLAY_STRING: 'Número de chasis',

  CREATE_CLAIM_CUSTOMER_TITLE: 'Cliente',
  CREATE_CLAIM_CUSTOMER_SUBTITLE: 'Ingresar información del cliente',
  CREATE_CLAIM_SUBMIT: 'Crear',
  CREATE_CLAIM_PREFILL: 'Rellenar',
  CREATE_CLAIM_CREATION_ERROR: 'Error al crear la inspección del siniestro',
  CREATE_CLAIM_CREATION_SUCCESS: 'Inspección de siniestro creado con éxito',
  CREATE_CLAIM_CREATION_CLOSE: 'Volver',
  CREATE_CLAIM_CREATION_CONTINUE: 'Continuar',
  CREATE_CLAIM_CREATION_COPY: 'Copiar link',
  CREATE_CLAIM_INVALID_PHONE_NUMBER: 'el número de teléfono celular inválido',

  REPORT_SUCCESS_MODAL_TITLE: 'Tu reporte se ha descargado exitosamente',
  REPORT_SUCCESS_MODAL_BUTTON: 'Cerrar',
  REPORT_TITLE: 'Reporte de reclamo',
  REPORT_SUBTITLE: 'Generar un reporte detallado con toda información de tus reclamo',
  REPORT_SUBMIT_BUTTON: 'Generar reporte',
  REPORT_FORM_SUBTITLE: 'Selecciona el rango de fechas sobre las que deseas generar tu reporte',
  REPORT_FORM_START_DATE: 'Fecha inicial',
  REPORT_FORM_END_DATE: 'Fecha final',
  REPORT_FORM_ERROR_MAX_RANGE: 'Las fechas de consulta deben ser igual o menor a 90 días',

  SEARCH_FILTER_BAR_WITH_PHOTOS: 'Con fotos',
};

export default { code, strings };
