import 'whatwg-fetch';
import { get, post, put, del } from '../common/request/api';

export const logMessage = body => post('log', body, true);

export const getClaims = queryObject => get('claims', false, queryObject);

export const getReport = queryObject =>
  get('claims/report', false, queryObject);

export const getClaim = claimId => get(`claims/${claimId}`);

export const getClaimCount = () => get(`claims/count`);

export const updateClaim = claim => put(`claims/${claim.id}`, claim);

export const updateClaimStatus = (claimId, body) =>
  put(`claims/${claimId}/status`, body, true);

export const getPhotos = (claimId, queryObject) =>
  get(`claims/${claimId}/photos`, false, queryObject);

export const getPhotosZip = claimId => get(`claims/${claimId}/photosZip`);

export const updatePhotos = (claimId, photos) =>
  put(`claims/${claimId}/photos`, { photos });

export const getDocuments = claimId => get(`claims/${claimId}/documents`);

export const uploadDocument = (claimId, document) =>
  put(`claims/${claimId}/documents`, { document });

export const deleteDocument = (claimId, document) =>
  del(`claims/${claimId}/documents`, {
    name: document.name,
  });

export const deleteClaim = (claimId, agentComments) =>
  del(`claims/${claimId}`, agentComments);

export const getCustomerMessages = claimId =>
  get(`claims/${claimId}/customerMessages`);

export const addCustomerMessage = (claimId, customerMessage) =>
  put(`claims/${claimId}/customerMessages`, customerMessage);

export const createClaim = claim => post('claim', claim, true);

export const resendCustomerNotifications = claimId =>
  post(`claims/${claimId}/customerEmails/resend`, {}, true);

export const assignClaim = (claimId, name) =>
  put(`claims/${claimId}/assignee`, { assignee: name }, true);

// If you're adding functions here, be sure to mock them in config/jest/setup.js
export default {
  addCustomerMessage,
  createClaim,
  deleteDocument,
  getCustomerMessages,
  getDocuments,
  getClaim,
  getClaims,
  getPhotos,
  logMessage,
  resendCustomerNotifications,
  updateClaim,
  updateClaimStatus,
  updatePhotos,
  uploadDocument,
  assignClaim,
};
