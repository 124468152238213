import constants from '@lmig-latam/adcl-common-lib/constants';
import { BodyText, Content, Disclaimer, Heading } from '@lmig/lmds-react';
import { IconWarning } from '@lmig/lmds-react/icons';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ClaimActions, SettingsActions } from '../../actions';
import {
  AppHeader,
  InlineLoader,
  ClaimTable,
  SearchBar,
  SideBar,
  DamageDescriptionModal,
} from '../../components';
import { SearchActions } from '../../redux/search-claims';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const {
  SEARCH_TITLE,
  SEARCH_SUBTITLE,
  SEARCH_NO_RESULTS_TITLE,
  SEARCH_NO_RESULTS_SUBTITLE,
  SEARCH_LOADING_LABEL,
  CLAIMS_TABLE_ACTION_DAMAGE_DESCRIPTION_BUTTON,
  CLAIMS_TABLE_HEADER_CREATED_ON,
} = localizedStrings;

const {
  STATUS_TYPES: {
    STATUS_AGENT_REVIEW,
    STATUS_AGENT_IN_PROGRESS,
    STATUS_AGENT_APPROVED,
    STATUS_CUSTOMER_RETAKE,
    STATUS_AGENT_REJECTED,
    STATUS_CUSTOMER_NEW,
    STATUS_CUSTOMER_IN_PROGRESS,
    STATUS_EXPIRED,
  },
} = constants;

const getAllStatus = () =>
  [
    STATUS_AGENT_REVIEW,
    STATUS_AGENT_IN_PROGRESS,
    STATUS_AGENT_APPROVED,
    STATUS_CUSTOMER_RETAKE,
    STATUS_AGENT_REJECTED,
    STATUS_CUSTOMER_NEW,
    STATUS_CUSTOMER_IN_PROGRESS,
    STATUS_EXPIRED,
  ].join(',');

const Search = ({
  claimActions: { getClaims, getClaimCount },
  settingsActions: { hideAppLoader },
}) => {
  const { filterSelected, filterText, currentLocation } = useSelector(
    store => store.searchFilter,
  );
  const [
    openDamageDescriptionModal,
    setOpenDamageDescriptionModal,
  ] = useState();

  const [damageDescription, setDamageDescription] = useState(null);
  const [inlineLoaderDisplayed, setInlineLoaderDisplayed] = useState(true);
  const [claimsInScope, setClaimsInScope] = useState([]);
  const [claimFilteredCount, setClaimFilteredCount] = useState();
  const [sortConfig, setSortConfig] = useState({
    key: CLAIMS_TABLE_HEADER_CREATED_ON,
    direction: 'ascending',
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchClaimsCounts = async () => {
      const count = await getClaimCount();
      dispatch(SearchActions.setCountClaims(count));
    };
    fetchClaimsCounts();
    hideAppLoader();
  }, [dispatch, getClaimCount, hideAppLoader]);

  useEffect(() => {
    const fetchClaims = async () => {
      setInlineLoaderDisplayed(true);
      let dataParams = {};

      dataParams = {
        status: getAllStatus(),
        pageIndex: currentLocation.currentPageIndex
          ? currentLocation.currentPageIndex
          : undefined,
      };

      if (filterSelected && !currentLocation.subStatusSelected) {
        dataParams.status = filterSelected.statuses.join(',');
      }

      if (filterSelected && currentLocation.subStatusSelected) {
        dataParams.status = currentLocation.subStatusSelected;
      }

      if (filterText && filterText.trim() !== '') {
        dataParams.status = getAllStatus();
        dataParams.filterString = filterText;
      }

      dataParams.mustHavePhotos = currentLocation.mustHavePhotos;

      const response = await getClaims(dataParams);
      if (response) {
        const { Items: claims, itemFilteredCount } = response;
        const claimSorted = claims.sort(
          (a, b) => new Date(b.date) - new Date(a.date),
        );
        setClaimFilteredCount(itemFilteredCount);
        setClaimsInScope(claimSorted);
        setInlineLoaderDisplayed(false);
      }
    };

    fetchClaims();
  }, [
    currentLocation.currentPageIndex,
    currentLocation.searchFilter,
    currentLocation.selectedStatuses,
    currentLocation.selectedSubStatus,
    currentLocation.mustHavePhotos,
    filterSelected,
    filterText,
    currentLocation,
    getClaims,
  ]);

  const requestSort = key => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    const sortableItems = [...claimsInScope];
    if (sortConfig !== null && key === CLAIMS_TABLE_HEADER_CREATED_ON) {
      sortableItems.sort((a, b) => {
        if (a.emailTokenCreationDate < b.emailTokenCreationDate) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a.emailTokenCreationDate > b.emailTokenCreationDate) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
      setSortConfig({ key, direction });
    }
    setClaimsInScope(sortableItems);
  };

  const onCloseModalDameDescription = () =>
    setOpenDamageDescriptionModal(false);

  const onOpenModalDameDescription = description => {
    setDamageDescription(description);
    setOpenDamageDescriptionModal(true);
  };

  const renderInlineLoader = () => (
    <div className="search-loader auto-left-right-margin">
      <InlineLoader isLoading />
      <Heading type="h4-light" className="auto-left-right-margin">
        {SEARCH_LOADING_LABEL}
      </Heading>
    </div>
  );

  const renderSearchResults = () => {
    if (inlineLoaderDisplayed) {
      return renderInlineLoader();
    }

    if (claimsInScope.length === 0) {
      return (
        <div>
          <IconWarning size="64" color="gray" />
          <Heading type="h4-light" className="auto-left-right-margin">
            {SEARCH_NO_RESULTS_TITLE}
          </Heading>
          <BodyText className="auto-left-right-margin">
            {SEARCH_NO_RESULTS_SUBTITLE}
          </BodyText>
        </div>
      );
    }

    return (
      <ClaimTable
        onOpenModal={onOpenModalDameDescription}
        claims={claimsInScope}
        count={claimFilteredCount}
        currentPageIndex={currentLocation.currentPageIndex}
        requestSort={requestSort}
        sortConfig={sortConfig}
      />
    );
  };

  return (
    <div className="adcl-screen">
      <AppHeader />
      <SideBar>
        <Content className="adcl-content">
          <div className="search-title-area">
            <Heading type="h3-light" className="auto-left-right-margin">
              {SEARCH_TITLE}
            </Heading>
            <Disclaimer>{SEARCH_SUBTITLE}</Disclaimer>
          </div>

          <SearchBar />

          {renderSearchResults()}
        </Content>
      </SideBar>

      {openDamageDescriptionModal && (
        <DamageDescriptionModal
          isOpen={openDamageDescriptionModal}
          closeModal={onCloseModalDameDescription}
          description={damageDescription}
          title={CLAIMS_TABLE_ACTION_DAMAGE_DESCRIPTION_BUTTON}
        />
      )}
    </div>
  );
};

Search.propTypes = {
  claimActions: ClaimActions.PropertyTypes.isRequired,
  settingsActions: SettingsActions.PropertyTypes.isRequired,
};

const mapDispatchToProps = dispatch => ({
  claimActions: bindActionCreators(ClaimActions, dispatch),
  settingsActions: bindActionCreators(SettingsActions, dispatch),
});

export default connect(null, mapDispatchToProps)(Search);
