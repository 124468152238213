import constants from '@lmig-latam/adcl-common-lib/constants';
import ApprovalPending from '../styles/images/steps/pending-approval.svg';
import ClientPending from '../styles/images/steps/pending-client.svg';
import Finish from '../styles/images/steps/finish.svg';
import ReviewPending from '../styles/images/steps/peding-review.svg';
import localizedStrings from '../utils/localizedStrings';

const {
  SEARCH_BAR_TABS_PENDING_REVIEW,
  SEARCH_BAR_TABS_PENDING_APPROVAL,
  SEARCH_BAR_TABS_FINISHED,
  SEARCH_BAR_TABS_PENDING_PER_CUSTOMER,
} = localizedStrings;

const {
  STATUS_TYPES: {
    STATUS_AGENT_REVIEW,
    STATUS_AGENT_IN_PROGRESS,
    STATUS_AGENT_APPROVED,
    STATUS_CUSTOMER_RETAKE,
    STATUS_AGENT_REJECTED,
    STATUS_CUSTOMER_NEW,
    STATUS_CUSTOMER_IN_PROGRESS,
    STATUS_EXPIRED,
  },
} = constants;

export default [
  {
    id: 1,
    title: SEARCH_BAR_TABS_PENDING_PER_CUSTOMER,
    statuses: [STATUS_CUSTOMER_NEW, STATUS_CUSTOMER_IN_PROGRESS],
    icon: ClientPending,
    count: 0,
    active: false,
  },
  {
    id: 2,
    title: SEARCH_BAR_TABS_PENDING_REVIEW,
    statuses: [STATUS_AGENT_REVIEW],
    icon: ReviewPending,
    count: 0,
    active: false,
  },
  {
    id: 3,
    title: SEARCH_BAR_TABS_PENDING_APPROVAL,
    statuses: [STATUS_AGENT_IN_PROGRESS, STATUS_CUSTOMER_RETAKE],
    icon: ApprovalPending,
    count: 0,
    active: false,
  },
  {
    id: 4,
    title: SEARCH_BAR_TABS_FINISHED,
    statuses: [STATUS_AGENT_APPROVED, STATUS_AGENT_REJECTED, STATUS_EXPIRED],
    icon: Finish,
    count: 0,
    active: false,
  },
];
