import constants from '@lmig-latam/adcl-common-lib/constants';
import PropTypes from 'prop-types';

const {
  STATUS_TYPES: {
    STATUS_CUSTOMER_NEW,
    STATUS_CUSTOMER_IN_PROGRESS,
    STATUS_AGENT_REVIEW,
    STATUS_AGENT_IN_PROGRESS,
    STATUS_AGENT_APPROVED,
    STATUS_AGENT_REJECTED,
    STATUS_CUSTOMER_RETAKE,
  },
} = constants;

export const userPropType = PropTypes.shape({
  name: PropTypes.string,
  username: PropTypes.string,
});

export const customerPropType = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  cellPhoneNumber: PropTypes.string,
  email: PropTypes.string,
});

export const vehiclePropType = PropTypes.shape({});

export const photoPropType = PropTypes.shape({
  name: PropTypes.string,
  url: PropTypes.string,
  status: PropTypes.string,
});

export const photoServicePropType = PropTypes.shape({
  photos: PropTypes.arrayOf(photoPropType),
  photoZipUrl: PropTypes.string,
});

export const documentPropType = PropTypes.shape({
  name: PropTypes.string,
  url: PropTypes.string,
});

export const newDocumentPropType = PropTypes.shape({
  name: PropTypes.string,
  data: PropTypes.string,
});

export const contentPropType = PropTypes.shape({});

export const customerMessagePropType = PropTypes.shape({
  customerMessage: PropTypes.string,
  agentId: PropTypes.string,
});

export const claimPropType = PropTypes.shape({
  id: PropTypes.string,
  status: PropTypes.string,
  comments: PropTypes.string,
  customer: customerPropType,
  vehicle: vehiclePropType,
});

export const allClaimsPropType = PropTypes.shape({
  [STATUS_CUSTOMER_NEW]: PropTypes.arrayOf(claimPropType),
  [STATUS_CUSTOMER_IN_PROGRESS]: PropTypes.arrayOf(claimPropType),
  [STATUS_AGENT_REVIEW]: PropTypes.arrayOf(claimPropType),
  [STATUS_AGENT_IN_PROGRESS]: PropTypes.arrayOf(claimPropType),
  [STATUS_AGENT_APPROVED]: PropTypes.arrayOf(claimPropType),
  [STATUS_AGENT_REJECTED]: PropTypes.arrayOf(claimPropType),
  [STATUS_CUSTOMER_RETAKE]: PropTypes.arrayOf(claimPropType),
});
