export const photoStatuses = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const notificationStatus = {
  CONFIRMATION: 'CONFIRMATION',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export const sortedKeys = [
  'CLAIM_PHOTO_VEHICLE_FRONT',
  'CLAIM_PHOTO_VEHICLE_REAR',
  'CLAIM_PHOTO_VEHICLE_RIGHT_SIDE',
  'CLAIM_PHOTO_VEHICLE_LEFT_SIDE',
  'CLAIM_PHOTO_VEHICLE_DASH',
  'CLAIM_PHOTO_VEHICLE_NUMBER_PLATE',
  'CLAIM_PHOTO_VEHICLE_CHASSIS_NUMBER',
  'CLAIM_PHOTO_PROPERTY_CARD_FRONT',
  'CLAIM_PHOTO_PROPERTY_CARD_BACK',
  'CLAIM_PHOTO_VEHICLE_DAMAGE_ONE',
  'CLAIM_PHOTO_VEHICLE_DAMAGE_TWO',
  'CLAIM_PHOTO_VEHICLE_DAMAGE_THREE',
  'CLAIM_PHOTO_VEHICLE_DAMAGE_FOUR',
  'CLAIM_PHOTO_VEHICLE_DAMAGE_FIVE',
];

export const HISTORY_POP = 'POP';

export const SIDEBAR_COOKIE_NAME = 'sidebar';
export const LOGIN_FAIL_COOKIE_NAME = 'login_failure_count';
export const SIDEBAR_COOKIE_EXPIRY_HOURS = 8760; // 1 year in hours

export const fieldValidation = {
  numericOnly: RegExp(/^[0-9]*$/i),
  alphaNumericOnly: RegExp(/^[a-zA-ZÀ-ÿ0-9]+$/i),
  currency: RegExp(/^[a-zA-ZÀ-ÿ0-9-,.$]+$/i),
  decimal: RegExp(/^[0-9.]*$/i),
};

export const cellPhoneFilter = {
  CO: /^(3)|[1-9]\d$/,
  CL: /^(9)|[1-9]\d$/,
  EC: /^(9)|[1-9]\d$/,
};
