import React from 'react';
import { useSelector } from 'react-redux';
import CardFilter from '../CardFilter/CardFilter';
import './styles.scss';

const MenuFilter = () => {
  const menuFilter = useSelector(store => store.searchFilter.menuFilter);

  return (
    <div className="menu-filter">
      {menuFilter.map(item => (
        <CardFilter key={item.id} itemFilter={item} />
      ))}
    </div>
  );
};

export default MenuFilter;
