import {
  devConfig,
  localhostConfig,
  nonProdConfig,
  prodConfig,
} from './configs';
import {
  isDevelopment,
  isLocalhost,
  isNonProduction,
} from './environmentsHelpers';

const getEnvironmentConfig = () => {
  let environmentConfig = prodConfig;

  if (isNonProduction()) {
    environmentConfig = nonProdConfig;
  } else if (isDevelopment()) {
    environmentConfig = devConfig;
  } else if (isLocalhost()) {
    environmentConfig = localhostConfig;
  }

  return environmentConfig;
};

export { getEnvironmentConfig };
