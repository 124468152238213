import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Login } from '..';
import { SettingsActions, UserActions } from '../../actions';
import { getCookie, setCookie } from '../../utils';
import { LOGIN_FAIL_COOKIE_NAME } from '../../utils/constants';
import { CREATE_CLAIM, DEFAULT, SEARCH } from '../../utils/navigationConstants';
import routesConfig from './routesConfig';
import history from '../../utils/history';

const App = ({
  settingsActions: { hideAppLoader, updateLoggedIn },
  userActions: { updateUserInfo },
  loggedIn,
  isRead,
  isReviewer,
  isCreator,
}) => {
  useEffect(() => {
    if (!window.location.search.includes('code=')) {
      hideAppLoader();
    }
  }, [hideAppLoader]);

  const resetLoginCookie = () => {
    setCookie(LOGIN_FAIL_COOKIE_NAME, 0);
  };

  // This gets round the intermittent 'connection reset' error triggered by Amplify
  const retryLogin = () => {
    // Get login failure cookie value
    const loginFailureCookieValue =
      parseInt(getCookie(LOGIN_FAIL_COOKIE_NAME), 10) || 0;
    // Limit auto login retry to 3
    if (loginFailureCookieValue > 2) {
      hideAppLoader();
      return;
    }
    // Update cookie value
    const newCookieValue =
      loginFailureCookieValue < 3 ? loginFailureCookieValue + 1 : 1;
    setCookie(LOGIN_FAIL_COOKIE_NAME, newCookieValue);
    Auth.federatedSignIn({ provider: 'lmidp' });
  };

  const setupAmplifyHubListener = () => {
    Hub.listen('auth', data => {
      switch (data.payload.event) {
        case 'signIn':
          updateLoggedIn(true);
          resetLoginCookie();
          updateUserInfo();
          break;
        case 'signIn_failure':
          retryLogin();
          updateLoggedIn(false);
          break;
        default:
          break;
      }
    });
  };

  setupAmplifyHubListener();

  const renderApp = () => {
    let defaultPath;

    if (isReviewer || isRead) {
      defaultPath = SEARCH;
    } else if (isCreator) {
      defaultPath = CREATE_CLAIM;
    }

    // Filter out any routes that are not supported by the current user groups
    const supportedRoutes = routesConfig.filter(
      route =>
        ((isReviewer || isRead) && route.isReviewerRoute) ||
        (isCreator && route.isCreatorRoute),
    );

    // Find the route that is considered 'default' for the current user groups and unshift a copy of it into the supportedRoutes array using DEFAULT as its path
    routesConfig
      .filter(route => defaultPath === route.path)
      .forEach(route => {
        supportedRoutes.unshift({ ...route, path: DEFAULT });
      });

    return (
      <Router history={history}>
        <Switch>
          {supportedRoutes.map(({ exact, path, render }) => (
            <Route
              key={path || 'NOT_FOUND'}
              exact={exact}
              path={path}
              render={render}
            />
          ))}
        </Switch>
      </Router>
    );
  };

  Auth.currentAuthenticatedUser()
    .then(() => {
      updateUserInfo();
      updateLoggedIn(true);
    })
    .catch(() => {
      updateLoggedIn(false);
    });

  return loggedIn ? renderApp() : <Login />;
};

const mapStateToProps = ({
  settings: { loggedIn },
  user: { isCreator, isReviewer, isRead },
}) => ({
  loggedIn,
  isCreator,
  isReviewer,
  isRead,
});

const mapDispatchToProps = dispatch => ({
  settingsActions: bindActionCreators(SettingsActions, dispatch),
  userActions: bindActionCreators(UserActions, dispatch),
});

App.propTypes = {
  settingsActions: SettingsActions.PropertyTypes.isRequired,
  userActions: UserActions.PropertyTypes.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  isCreator: PropTypes.bool.isRequired,
  isReviewer: PropTypes.bool.isRequired,
  isRead: PropTypes.bool.isRequired,
};

// Praise be to hihuz, mightest of commenters, for the wisdom that is recorded here
// https://github.com/ReactTraining/react-router/issues/4671#issuecomment-285320076
export default connect(mapStateToProps, mapDispatchToProps)(App);
