import Auth from '@aws-amplify/auth';
import PropTypes from 'prop-types';
import {
  getAllowedCountries,
  isAdmin,
  isCreator,
  isRead,
  isReviewer,
} from '../utils';
import { UPDATE_USER_INFO } from './ActionTypes';

export default {
  PropertyTypes: PropTypes.shape({
    updateUserInfo: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  }),

  updateUserInfo() {
    return async dispatch =>
      Auth.currentAuthenticatedUser().then(cognitoUser => {
        const user = {
          name: cognitoUser.attributes.name,
          username: cognitoUser.username,
          isReviewer: isReviewer(cognitoUser),
          isCreator: isCreator(cognitoUser),
          isRead: isRead(cognitoUser),
          isAdmin: isAdmin(cognitoUser),
          allowedCountries: getAllowedCountries(cognitoUser),
        };
        dispatch({
          type: UPDATE_USER_INFO,
          user,
        });
      });
  },

  logout() {
    return () => {
      Auth.signOut();
    };
  },
};
