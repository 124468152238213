import { AlphanumericInput } from '@lmig/lmds-react';
import classNames from 'classnames';
import { useField } from 'informed';
import PropTypes from 'prop-types';
import React, { createRef, useState } from 'react';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import { getCountryCallingCode } from 'react-phone-number-input/input';
import CellphoneField from '../CellphoneField/CellphoneField';
import './styles.scss';
import 'react-phone-number-input/style.css';
import { getCountryCode } from '../../config/environments/environmentsHelpers';
import { cellPhoneFilter } from '../../utils/constants';
import clFlag from './country-flag/cl.svg';
import coFlag from './country-flag/co.svg';
import ecFlag from './country-flag/ec.svg';

const InformedAlphanumericInput = props => {
  const {
    className,
    id,
    labelVisual,
    onChange,
    onBlur,
    initialValue,
    disabled,
    field,
  } = props;

  const countryFlags = {
    CO: coFlag,
    EC: ecFlag,
    CL: clFlag,
  };

  const countryCode = getCountryCode().toUpperCase();
  const ref = createRef();
  const [phoneValue, setPhoneValue] = useState(initialValue);
  const {
    fieldState: { value, error },
    fieldApi: { setValue },
  } = useField({ ...props });

  const isCellphoneField = () => 'cellPhoneNumber'.includes(field);

  const phoneNumberFormater = (cellPhone, isOnBlur) => {
    const phoneNumber = cellPhone.split(' ');
    if (isOnBlur) {
      return cellPhone.length > 1
        ? `${getCountryCallingCode(countryCode)}-${phoneNumber
            .slice()
            .join('')}`
        : undefined;
    }
    return `${phoneNumber[0].replace('+', '')}-${phoneNumber
      .slice(1)
      .join('')}`;
  };

  const handlerOnBlur = event => {
    let trimmedValue;
    if (isCellphoneField()) {
      trimmedValue = phoneNumberFormater(event.target.value.trim(), true);
    } else {
      trimmedValue = event.target.value.trim();
    }

    setValue(trimmedValue);
    if (onBlur) {
      onBlur(trimmedValue);
    } else {
      onChange(event);
    }
  };

  const handlerOnChange = event => {
    const trimmedValue = event.target
      ? event.target.value.trim()
      : event.trim();
    setValue(trimmedValue);
    onChange(event);
  };

  const handlerOnChangePhone = event => {
    setPhoneValue(event);
    setValue(phoneNumberFormater(formatPhoneNumberIntl(event), false));
  };

  return (
    <div>
      {!isCellphoneField() ? (
        <AlphanumericInput
          id={id}
          className={classNames([
            className,
            {
              'validation-input-field-error': error,
            },
          ])}
          labelVisual={labelVisual}
          value={value || initialValue}
          onChange={handlerOnChange}
          onBlur={handlerOnBlur}
          disabled={disabled}
        />
      ) : (
        <PhoneInput
          defaultCountry={countryCode}
          countries={[countryCode]}
          ref={ref}
          inputComponent={CellphoneField}
          id={id}
          filter={cellPhoneFilter[countryCode]}
          value={phoneValue}
          labelVisual={labelVisual}
          error={error}
          flagUrl={countryFlags[countryCode]}
          className={classNames([
            className,
            {
              'validation-input-field-error': error,
            },
          ])}
          disabled={disabled}
          onChange={handlerOnChangePhone}
          onBlur={handlerOnBlur}
        />
      )}
    </div>
  );
};

InformedAlphanumericInput.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  labelVisual: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  initialValue: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.string,
};

InformedAlphanumericInput.defaultProps = {
  className: null,
  labelVisual: null,
  onChange: () => {},
  onBlur: () => {},
  initialValue: '',
  disabled: false,
  field: null,
};
export default InformedAlphanumericInput;
