import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { SearchActions } from '../../redux/search-claims';
import './styles.scss';

const CardTitle = ({ title }) => (
  <div className="card-filter__title">
    <p>{title}</p>
  </div>
);

const CardContent = ({ count, icon }) => (
  <div className="card-filter__content">
    <p className="card-filter__content__count">{count}</p>
    <img className="card-filter__content__icon" src={icon} alt="" />
  </div>
);

function CardFilter({ itemFilter }) {
  const { id, title, count, icon, active } = itemFilter;
  const isActive = `${active ? 'card-filter--active' : ''} `;
  const dispatch = useDispatch();

  const onSetStepFilter = () => {
    dispatch(SearchActions.setStepFilter(itemFilter));
  };

  return (
    <div
      key={id}
      role="button"
      className={`card-filter ${isActive}`}
      onClick={() => onSetStepFilter(itemFilter)}
    >
      <div className="card-filter__container">
        <CardTitle title={title} />
        <CardContent count={count} icon={icon} />
      </div>
    </div>
  );
}

CardFilter.propTypes = {
  itemFilter: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    icon: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }).isRequired,
};

CardContent.propTypes = {
  count: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
};

CardTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CardFilter;
