import {
  BodyText,
  Button,
  Content,
  Disclaimer,
  GridCol,
  GridRow,
  Heading,
} from '@lmig/lmds-react';
import { IconDocument } from '@lmig/lmds-react/icons';
import classNames from 'classnames';
import { Form, Text } from 'informed';
import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ClaimActions, SettingsActions } from '../../actions';
import {
  AppHeader,
  CircleImage,
  ErrorsList,
  Panel,
  SideBar,
} from '../../components';
import { downloadFileFromUrl } from '../../utils';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const {
  REPORT_SUCCESS_MODAL_TITLE,
  REPORT_SUCCESS_MODAL_BUTTON,
  REPORT_TITLE,
  REPORT_SUBTITLE,
  REPORT_SUBMIT_BUTTON,
  REPORT_FORM_SUBTITLE,
  REPORT_FORM_START_DATE,
  REPORT_FORM_END_DATE,
} = localizedStrings;

const Report = ({
  claimActions: { getReport },
  settingsActions: { hideAppLoader, showAppLoader },
}) => {
  const [formApi, setFormApi] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const submitReportRequest = async () => {
    showAppLoader();

    const {
      values: { startDate, endDate },
    } = formApi.getState();

    const { reportUrl } = await getReport({
      startDate,
      endDate,
    });

    const fileNamePrefix = 'Reports/';
    const fileNameSuffix = '.xlsx';

    const fileName = reportUrl.substring(
      reportUrl.indexOf(fileNamePrefix) + fileNamePrefix.length,
      reportUrl.indexOf(fileNameSuffix) + fileNameSuffix.length,
    );

    setIsModalOpen(true);

    downloadFileFromUrl(reportUrl, fileName);

    formApi.reset();

    hideAppLoader();
  };

  const setInformedApi = informedApi => {
    setFormApi(informedApi);
  };

  const renderReportSuccessModal = () => (
    <ReactModal
      isOpen={isModalOpen}
      className="modal-container"
      overlayClassName="modal-container-overlay"
    >
      <div className="modal-content">
        <CircleImage>
          <IconDocument size="64" />
        </CircleImage>
        <Heading type="h3-light">{REPORT_SUCCESS_MODAL_TITLE}</Heading>
        <Button
          onClick={() => {
            setIsModalOpen(false);
          }}
        >
          {REPORT_SUCCESS_MODAL_BUTTON}
        </Button>
      </div>
    </ReactModal>
  );

  const validateStartDate = (startDate, { endDate }) => {
    if (!startDate) {
      return 'no value';
    }

    if (startDate && !endDate) {
      formApi.setValue('endDate', startDate);
    }

    if (startDate > endDate) {
      formApi.setValue('endDate', startDate);
    }

    return undefined;
  };

  const validateEndDate = (endDate, { startDate }) => {
    if (!endDate) {
      return 'no value';
    }

    if (endDate < startDate) {
      formApi.setValue('startDate', endDate);
    }

    return undefined;
  };

  return (
    <div className="adcl-screen">
      {renderReportSuccessModal()}
      <AppHeader />
      <SideBar>
        <Content className="adcl-content">
          <Heading type="h3-light" className="auto-left-right-margin">
            {REPORT_TITLE}
          </Heading>
          <Disclaimer>{REPORT_SUBTITLE}</Disclaimer>
          <Panel className="adcl-report-panel">
            <GridRow gutters justify="center">
              <GridCol base={12}>
                <CircleImage size="small">
                  <IconDocument size="48" />
                </CircleImage>
              </GridCol>
              <GridCol base={6}>
                <BodyText type="lead" className="auto-left-right-margin">
                  {REPORT_FORM_SUBTITLE}
                </BodyText>
              </GridCol>

              <Form getApi={setInformedApi} onSubmit={submitReportRequest}>
                {({
                  formState: {
                    errors: {
                      startDate: startDateErrors,
                      endDate: endDateErrors,
                    },
                    error,
                  },
                }) => (
                  <GridRow gutters justify="center">
                    <GridCol base={6}>
                      <label>
                        {REPORT_FORM_START_DATE}
                        <Text
                          field="startDate"
                          type="date"
                          className={classNames([
                            'adcl-report-input',
                            {
                              'validation-input-field-error':
                                startDateErrors || error,
                            },
                          ])}
                          validate={validateStartDate}
                          validateOnChange
                        />
                      </label>
                    </GridCol>
                    <GridCol base={6}>
                      <label>
                        {REPORT_FORM_END_DATE}
                        <Text
                          field="endDate"
                          type="date"
                          className={classNames([
                            'adcl-report-input',
                            {
                              'validation-input-field-error':
                                endDateErrors || error,
                            },
                          ])}
                          validate={validateEndDate}
                          validateOnChange
                        />
                      </label>
                    </GridCol>
                    {error && (
                      <GridCol base={12}>
                        <ErrorsList errors={[error]} />
                      </GridCol>
                    )}
                    <GridCol base={12}>
                      <Button type="submit">{REPORT_SUBMIT_BUTTON}</Button>
                    </GridCol>
                  </GridRow>
                )}
              </Form>
            </GridRow>
          </Panel>
        </Content>
      </SideBar>
    </div>
  );
};

Report.propTypes = {
  claimActions: ClaimActions.PropertyTypes.isRequired,
  settingsActions: SettingsActions.PropertyTypes.isRequired,
};

const mapDispatchToProps = dispatch => ({
  claimActions: bindActionCreators(ClaimActions, dispatch),
  settingsActions: bindActionCreators(SettingsActions, dispatch),
});

export default connect(null, mapDispatchToProps)(Report);
