import React, { useState } from 'react';
import { IconSearch } from '@lmig/lmds-react/icons';
import { Form, Text } from 'informed';
import { useDispatch, useSelector } from 'react-redux';
import { SearchActions } from '../../redux/search-claims';
import localizedStrings from '../../utils/localizedStrings';

const { SEARCH_BAR_INPUT_PLACEHOLDER } = localizedStrings;

function InputSearch() {
  const [formApi, setFormApi] = useState();
  const dispatch = useDispatch();
  const { filterText } = useSelector(store => store.searchFilter);

  const onChangeText = value => {
    if (value.trim().length === 1) {
      dispatch(SearchActions.resetFilterSteps());
    }
  };

  const submitSearch = () => {
    const { filter } = formApi.getState().values;
    dispatch(SearchActions.onChangeSearchTextClaims(filter));
    dispatch(SearchActions.resetFilterSteps());
    // formApi.reset();
  };

  return (
    <Form getApi={setFormApi}>
      <div className="adcl-search-bar-form">
        <Text
          type="text"
          field="filter"
          autoComplete="off"
          value={filterText}
          initialValue={filterText}
          onChange={e => onChangeText(e.target.value)}
          className="adcl-search-bar-input"
          placeholder={SEARCH_BAR_INPUT_PLACEHOLDER}
        />
        <button
          type="submit"
          className="adcl-search-bar-search-icon"
          onClick={submitSearch}
        >
          <IconSearch size="16" />
        </button>
      </div>
    </Form>
  );
}

export default InputSearch;
