import { getCountryCode } from '../../config/environments/environmentsHelpers';
import { buildQueryStringFromObject } from './build-request';
import { sendServiceCall } from './call-request';
import { restMethods } from './http-methods';

const { GET, POST, PUT, DELETE } = restMethods;

const postRequest = (path, body, disableErrorHandling) =>
  sendServiceCall(path, body, disableErrorHandling, POST);

const getRequest = (path, disableErrorHandling, queryObject = {}) => {
  const pathWithQueryParams = `${path}${buildQueryStringFromObject({
    ...queryObject,
    countryCode: getCountryCode(),
  })}`;

  return sendServiceCall(pathWithQueryParams, null, disableErrorHandling, GET);
};

const putRequest = (path, body, disableErrorHandling) =>
  sendServiceCall(path, body, disableErrorHandling, PUT);

const deleteRequest = (path, queryObject = {}, disableErrorHandling) => {
  const pathWithQueryParams = `${path}${buildQueryStringFromObject({
    ...queryObject,
    countryCode: getCountryCode(),
  })}`;

  return sendServiceCall(
    pathWithQueryParams,
    null,
    disableErrorHandling,
    DELETE,
  );
};

export {
  getRequest as get,
  postRequest as post,
  putRequest as put,
  deleteRequest as del,
};
