import { Button, Content, IconChevronLeft, IconExit } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { HISTORY_POP } from '../../utils/constants';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const { MODAL_PRIMARY_BUTTON_LABEL } = localizedStrings;

const Modal = props => {
  const {
    isOpen,
    primaryButtonLabel,
    primaryButtonHandler,
    primaryButtonDisabled,
    secondaryButtonLabel,
    secondaryButtonHandler,
    secondaryButtonDisabled,
    closeButtonHandler,
    backButtonHandler,
    centerContent,
    children,
    id,
  } = props;

  const displayPrimaryButton = primaryButtonHandler !== null;
  const displaySecondaryButton = secondaryButtonHandler !== null;
  const displayCloseButton = closeButtonHandler !== null;
  const displayBackButton = backButtonHandler !== null;

  const displayControlHeader = !!(displayBackButton || displayCloseButton);
  const displayFooter = !!(displayPrimaryButton || displaySecondaryButton);

  const centerContentClass = centerContent ? 'modal-center-content' : '';

  const history = useHistory();

  useEffect(() => {
    // this will execute anytime a prop from the array below changes
    if (isOpen) {
      history.push(history.location.pathname);
    }

    const unsubscribe = history.listen((location, action) => {
      if (action === HISTORY_POP) {
        if (closeButtonHandler) {
          closeButtonHandler();
        } else if (backButtonHandler) {
          backButtonHandler();
        }
      }
    });
    // returning a function in useEffect is equivilant to componetWillUnmount
    return unsubscribe;
  }, [backButtonHandler, closeButtonHandler, history, isOpen]);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={primaryButtonHandler}
      className="modal"
      overlayClassName="modal-overlay"
      id={id}
    >
      <div className="modal-scrollable-content">
        {displayControlHeader && (
          <div className="modal-controls-header">
            {displayBackButton && (
              <Button
                className="modal-back-icon"
                type="button"
                onClick={backButtonHandler}
                icon
              >
                <IconChevronLeft />
              </Button>
            )}
            {displayCloseButton && (
              <Button
                className="modal-close-icon"
                type="button"
                onClick={closeButtonHandler}
                icon
              >
                <IconExit />
              </Button>
            )}
          </div>
        )}
        <Content className={centerContentClass}>{children}</Content>
        {displayFooter && (
          <div className="pad-footer-buttons">
            {displayPrimaryButton && (
              <div className="align-center">
                <Button
                  type="button"
                  onClick={primaryButtonHandler}
                  disabled={primaryButtonDisabled}
                >
                  {primaryButtonLabel}
                </Button>
              </div>
            )}
            {displaySecondaryButton && (
              <div className="align-center modal-secondary-button">
                <Button
                  type="button"
                  level="secondary"
                  onClick={secondaryButtonHandler}
                  disabled={secondaryButtonDisabled}
                >
                  {secondaryButtonLabel}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,

  primaryButtonLabel: PropTypes.string,
  primaryButtonHandler: PropTypes.func,
  primaryButtonDisabled: PropTypes.bool,

  secondaryButtonLabel: PropTypes.string,
  secondaryButtonHandler: PropTypes.func,
  secondaryButtonDisabled: PropTypes.bool,

  closeButtonHandler: PropTypes.func,
  backButtonHandler: PropTypes.func,

  centerContent: PropTypes.bool,

  children: PropTypes.node.isRequired,
  id: PropTypes.string,
};

Modal.defaultProps = {
  primaryButtonLabel: MODAL_PRIMARY_BUTTON_LABEL,
  primaryButtonHandler: null,
  primaryButtonDisabled: false,

  secondaryButtonLabel: null,
  secondaryButtonHandler: null,
  secondaryButtonDisabled: false,

  closeButtonHandler: null,
  backButtonHandler: null,

  centerContent: false,
  id: null,
};

export default Modal;
