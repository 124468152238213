import constants from '@lmig-latam/adcl-common-lib/constants';
import { Button, Caption, Content, Heading } from '@lmig/lmds-react';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppHeader, CircleImage, SideBar } from '../../components';
import localizedStrings from '../../utils/localizedStrings';
import { DEFAULT } from '../../utils/navigationConstants';
import { navigate } from '../../utils/NavigationUtils';
import claimAcceptedImage from './claim-accepted.svg';
import claimRejectedImage from './claim-rejected.svg';
import './styles.scss';

const {
  STATUS_TYPES: { STATUS_AGENT_APPROVED, STATUS_AGENT_REJECTED },
} = constants;

const {
  CLAIM_COMPLETE_ACCEPTED_TITLE,
  CLAIM_COMPLETE_ACCEPTED_SUBTITLE,
  CLAIM_COMPLETE_REJECTED_TITLE,
  CLAIM_COMPLETE_REJECTED_SUBTITLE,
  CLAIM_COMPLETE_BUTTON_LABEL,
} = localizedStrings;

const ClaimComplete = () => {
  const { state: { status } = {} } = useLocation();

  const navigateToSearchScreen = () => {
    navigate(DEFAULT);
  };

  const getScreenProps = () => {
    switch (status) {
      case STATUS_AGENT_REJECTED:
        return {
          title: CLAIM_COMPLETE_REJECTED_TITLE,
          subtitle: CLAIM_COMPLETE_REJECTED_SUBTITLE,
          imageSource: claimRejectedImage,
        };
      case STATUS_AGENT_APPROVED:
      default:
        return {
          title: CLAIM_COMPLETE_ACCEPTED_TITLE,
          subtitle: CLAIM_COMPLETE_ACCEPTED_SUBTITLE,
          imageSource: claimAcceptedImage,
        };
    }
  };

  const { title, subtitle, imageSource } = getScreenProps();

  return (
    <div className="adcl-screen">
      <AppHeader />
      <SideBar>
        <Content className="adcl-content">
          <div className="claim-complete-content">
            <Heading type="h2-light" className="auto-left-right-margin">
              {title}
            </Heading>
            <Caption className="gray-text">{subtitle}</Caption>
            <CircleImage>
              <img src={imageSource} alt="" />
            </CircleImage>
            <Button onClick={navigateToSearchScreen}>
              {CLAIM_COMPLETE_BUTTON_LABEL}
            </Button>
          </div>
        </Content>
      </SideBar>
    </div>
  );
};

export default connect(null, null)(ClaimComplete);
